import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Chip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Grid,
  CircularProgress,
  Skeleton,
  TablePagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Visibility as ViewIcon,
  LocalShipping as ShippingIcon,
  Done as DoneIcon,
  Search as SearchIcon,
  Payment as PaymentIcon,
} from "@mui/icons-material";
import api from "../../../services/api";

const STATUS_COLORS = {
  pending: "warning",
  paid: "info",
  shipped: "primary",
  delivered: "success",
  cancelled: "error",
};

const STATUS_LABELS = {
  pending: "Čaká na platbu",
  paid: "Zaplatené",
  shipped: "Odoslané",
  delivered: "Doručené",
  cancelled: "Zrušené",
};

const countryFlags = {
  SK: "🇸🇰",
  CZ: "🇨🇿",
  all: "🌍",
};

const renderCarrierWithCountry = (option) => {
  const flag = countryFlags[option.country] || countryFlags.all;
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <span>{flag}</span>
      <span>{option.name}</span>
    </Box>
  );
};

const TableSkeleton = () => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Číslo objednávky</TableCell>
          <TableCell>Dátum</TableCell>
          <TableCell>Zákazník</TableCell>
          <TableCell>Suma</TableCell>
          <TableCell>Stav</TableCell>
          <TableCell>Tracking číslo</TableCell>
          <TableCell>Akcie</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[...Array(5)].map((_, index) => (
          <TableRow key={index}>
            <TableCell>
              <Skeleton width={100} />
            </TableCell>
            <TableCell>
              <Skeleton width={80} />
            </TableCell>
            <TableCell>
              <Skeleton width={150} />
            </TableCell>
            <TableCell>
              <Skeleton width={70} />
            </TableCell>
            <TableCell>
              <Skeleton
                width={100}
                height={32}
                variant="rectangular"
                sx={{ borderRadius: 1 }}
              />
            </TableCell>
            <TableCell>
              <Skeleton width={120} />
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Skeleton variant="circular" width={30} height={30} />
                <Skeleton variant="circular" width={30} height={30} />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default function EshopOrdersTable() {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [filters, setFilters] = useState({
    orderNumber: "",
    customerName: "",
    status: "all",
  });
  const [shippingDialogOpen, setShippingDialogOpen] = useState(false);
  const [selectedOrderForShipping, setSelectedOrderForShipping] =
    useState(null);
  const [shippingData, setShippingData] = useState({
    tracking_number: "",
    shipping_provider: "",
  });
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchOrders = async () => {
    try {
      const queryParams = new URLSearchParams();

      queryParams.append("page", page + 1);
      queryParams.append("page_size", rowsPerPage);

      if (filters.orderNumber) {
        queryParams.append("order_number", filters.orderNumber);
      }
      if (filters.customerName) {
        queryParams.append("customer_name", filters.customerName);
      }
      if (filters.status && filters.status !== "all") {
        queryParams.append("status", filters.status);
      }

      const response = await api.get(
        `/eshop/orders/?${queryParams.toString()}`
      );
      setOrders(response.data.results || []);
      setTotalCount(response.data.count || 0);
    } catch (error) {
      console.error("Failed to fetch orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDeliveryOptions = async () => {
    try {
      const response = await api.get("eshop/delivery-options/");
      if (response.data && Array.isArray(response.data.results)) {
        setDeliveryOptions(response.data.results);
      } else if (Array.isArray(response.data)) {
        setDeliveryOptions(response.data);
      }
    } catch (error) {
      console.error("Error fetching delivery options:", error);
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchDeliveryOptions();
  }, [filters, page, rowsPerPage]);

  const handleStatusChange = async (orderId, newStatus) => {
    if (newStatus === "mark_shipped") {
      const order = orders.find((o) => o.id === orderId);
      const providerOption = deliveryOptions.find(
        (opt) => opt.name === order.shipping_provider
      );
      setSelectedOrderForShipping(order);
      setShippingData({
        tracking_number: "",
        shipping_provider: providerOption?.id || "",
      });
      setShippingDialogOpen(true);
    } else {
      try {
        await api.post(`/eshop/orders/${orderId}/${newStatus}/`);
        fetchOrders();
      } catch (error) {
        console.error("Failed to update order status:", error);
      }
    }
  };

  const handleShippingSubmit = async () => {
    try {
      await api.post(
        `/eshop/orders/${selectedOrderForShipping.id}/mark_shipped/`,
        shippingData
      );
      setShippingDialogOpen(false);
      setSelectedOrderForShipping(null);
      setShippingData({ tracking_number: "", shipping_provider: "" });
      fetchOrders();
    } catch (error) {
      console.error("Failed to mark order as shipped:", error);
    }
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setDialogOpen(true);
  };

  const handleFilterChange = (field) => (event) => {
    setFilters((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2} alignItems="center">
          {loading ? (
            <>
              <Grid item xs={12} sm={4}>
                <Skeleton height={40} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton height={40} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Skeleton height={40} />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Číslo objednávky"
                  variant="outlined"
                  size="small"
                  value={filters.orderNumber}
                  onChange={handleFilterChange("orderNumber")}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Zákazník"
                  variant="outlined"
                  size="small"
                  value={filters.customerName}
                  onChange={handleFilterChange("customerName")}
                  InputProps={{
                    startAdornment: (
                      <SearchIcon sx={{ color: "action.active", mr: 1 }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth size="small">
                  <InputLabel>Stav objednávky</InputLabel>
                  <Select
                    value={filters.status}
                    label="Stav objednávky"
                    onChange={handleFilterChange("status")}
                  >
                    <MenuItem value="all">Všetky stavy</MenuItem>
                    {Object.entries(STATUS_LABELS).map(([value, label]) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      {loading ? (
        <TableSkeleton />
      ) : orders.length > 0 ? (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Číslo objednávky</TableCell>
                  <TableCell>Dátum</TableCell>
                  <TableCell>Zákazník</TableCell>
                  <TableCell>Suma</TableCell>
                  <TableCell>Stav</TableCell>
                  <TableCell>Tracking číslo</TableCell>
                  <TableCell>Akcie</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <TableRow key={order.id}>
                    <TableCell>{order.order_number}</TableCell>
                    <TableCell>
                      {new Date(order.created_at).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{order.shipping_name}</TableCell>
                    <TableCell>{order.total_amount}€</TableCell>
                    <TableCell>
                      <Chip
                        label={STATUS_LABELS[order.status]}
                        color={STATUS_COLORS[order.status]}
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {order.tracking_number ? (
                        <Chip
                          label={order.tracking_number}
                          size="small"
                          variant="outlined"
                        />
                      ) : (
                        "-"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={() => handleViewDetails(order)}
                      >
                        <ViewIcon />
                      </IconButton>
                      {order.status === "pending" && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleStatusChange(order.id, "mark_paid")
                          }
                        >
                          <PaymentIcon />
                        </IconButton>
                      )}
                      {order.status === "paid" && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleStatusChange(order.id, "mark_shipped")
                          }
                        >
                          <ShippingIcon />
                        </IconButton>
                      )}
                      {order.status === "shipped" && (
                        <IconButton
                          size="small"
                          onClick={() =>
                            handleStatusChange(order.id, "mark_delivered")
                          }
                        >
                          <DoneIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 20, 50]}
            labelRowsPerPage="Počet na stránku"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} z ${count !== -1 ? count : `viac ako ${to}`}`
            }
          />
        </Paper>
      ) : (
        <Box sx={{ textAlign: "center", my: 3 }}>
          <Typography color="text.secondary">
            Neboli nájdené žiadne objednávky
          </Typography>
        </Box>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {selectedOrder && (
          <>
            <DialogTitle>
              {loading ? (
                <Skeleton width={200} />
              ) : (
                `Objednávka #${selectedOrder.order_number}`
              )}
            </DialogTitle>
            <DialogContent>
              {loading ? (
                <Box sx={{ mt: 2 }}>
                  <Skeleton height={30} width={150} />
                  <Skeleton height={100} />
                  <Skeleton height={30} width={150} sx={{ mt: 2 }} />
                  <Skeleton height={100} />
                  <Skeleton height={30} width={150} sx={{ mt: 2 }} />
                  <Skeleton height={200} />
                </Box>
              ) : (
                <>
                  <Typography variant="h6">Dodacie údaje</Typography>
                  <Typography>
                    {selectedOrder.shipping_name}
                    <br />
                    {selectedOrder.shipping_address}
                    <br />
                    {selectedOrder.shipping_email}
                    <br />
                    {selectedOrder.shipping_phone}
                  </Typography>

                  {selectedOrder.company_name && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6">Firemné údaje</Typography>
                      <Typography>
                        {selectedOrder.company_name}
                        <br />
                        IČO: {selectedOrder.company_id}
                        <br />
                        DIČ: {selectedOrder.tax_id}
                        {selectedOrder.vat_id && (
                          <>
                            <br />
                            IČ DPH: {selectedOrder.vat_id}
                          </>
                        )}
                      </Typography>
                    </Box>
                  )}

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Položky</Typography>
                    <TableContainer>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Produkt</TableCell>
                            <TableCell align="right">Množstvo</TableCell>
                            <TableCell align="right">Cena</TableCell>
                            <TableCell align="right">Spolu</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedOrder.items?.map((item) => (
                            <TableRow key={item.id}>
                              <TableCell>{item.product_name}</TableCell>
                              <TableCell align="right">
                                {item.quantity}
                              </TableCell>
                              <TableCell align="right">{item.price}€</TableCell>
                              <TableCell align="right">
                                {(item.price * item.quantity).toFixed(2)}€
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <Box sx={{ mt: 2 }}>
                    <Typography variant="h6">Súhrn</Typography>
                    <Typography>
                      Suma bez DPH:{" "}
                      {(
                        selectedOrder.total_amount - selectedOrder.vat_amount
                      ).toFixed(2)}
                      €
                      <br />
                      DPH: {selectedOrder.vat_amount}€
                      <br />
                      Celkom s DPH: {selectedOrder.total_amount}€
                    </Typography>
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>
                {loading ? <Skeleton width={60} /> : "Zavrieť"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      <Dialog
        open={shippingDialogOpen}
        onClose={() => setShippingDialogOpen(false)}
      >
        <DialogTitle>Označiť ako odoslané</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              fullWidth
              label="Tracking číslo"
              value={shippingData.tracking_number}
              onChange={(e) =>
                setShippingData({
                  ...shippingData,
                  tracking_number: e.target.value,
                })
              }
            />
            <FormControl fullWidth>
              <InputLabel>Dopravca</InputLabel>
              <Select
                value={shippingData.shipping_provider}
                label="Dopravca"
                onChange={(e) =>
                  setShippingData({
                    ...shippingData,
                    shipping_provider: e.target.value,
                  })
                }
              >
                {deliveryOptions.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {renderCarrierWithCountry(option)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShippingDialogOpen(false)}>Zrušiť</Button>
          <Button
            onClick={handleShippingSubmit}
            variant="contained"
            color="primary"
            disabled={
              !shippingData.tracking_number || !shippingData.shipping_provider
            }
          >
            Potvrdiť
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

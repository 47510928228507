import React, { createContext, useContext, useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import api from "../services/api";
import { cartService } from "../services/cartService";

const CartContext = createContext();

const CART_CACHE_KEY = "cartData";

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error("useCart must be used within a CartProvider");
  }
  return context;
};

export const CartProvider = ({ children }) => {
  // Initialize cartItems from localStorage if available
  const [cartItems, setCartItems] = useState(() => {
    const cached = localStorage.getItem(CART_CACHE_KEY);
    return cached ? JSON.parse(cached) : [];
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);

  // Update localStorage whenever cartItems changes
  useEffect(() => {
    localStorage.setItem(CART_CACHE_KEY, JSON.stringify(cartItems));
  }, [cartItems]);

  const fetchCart = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await cartService.getCart();
      const activeCart = response[0];
      if (activeCart) {
        const transformedItems = activeCart.items.map((item) => ({
          id: item.id,
          name: item.product_name,
          price_vat: parseFloat(item.total_price) / item.quantity,
          quantity: item.quantity,
          img_url: item.shopping_product.product_image_url,
          points: item.points,
          cashback: item.cashback,
        }));
        setCartItems(transformedItems);
      } else {
        setCartItems([]);
      }
    } catch (err) {
      setError("Failed to load cart");
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const addToCart = async (item) => {
    try {
      setIsLoading(true);
      setError(null);

      console.log("Adding item to cart:", item);

      const cartItem = {
        product_offer_id: item.id,
        product_id: item.product_id,
        quantity: 1,
      };

      console.log("Sending to API:", cartItem);

      const response = await cartService.addToCart(cartItem);
      console.log("API response:", response);

      // Optimistically update the cart count
      const newItem = {
        id: item.id,
        name: item.name,
        price_vat: item.price_vat,
        quantity: 1,
        img_url: item.img_url,
      };

      setCartItems((prev) => [...prev, newItem]);

      // Then fetch the actual updated cart
      await fetchCart();
      setIsCartOpen(true);
    } catch (err) {
      console.error("Detailed error:", {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
      });

      if (err.response?.status === 404) {
        setError("Product not found");
      } else if (err.response?.status === 400) {
        setError(err.response.data.message || "Invalid request");
      } else {
        setError("Failed to add item to cart. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getCartTotal = () => {
    const total = cartItems.reduce(
      (sum, item) => sum + item.price_vat * item.quantity,
      0
    );
    const vat = total * 0.2;
    return {
      total,
      vat,
    };
  };

  const getCartCount = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const hasItems = () => {
    return cartItems.length > 0;
  };

  // Fetch cart data on initial load
  useEffect(() => {
    fetchCart();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        isCartOpen,
        setIsCartOpen,
        isLoading,
        error,
        fetchCart,
        getCartTotal,
        getCartCount,
        addToCart,
        hasItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

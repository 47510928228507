import React, { useState } from "react";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";

const AutoselectFetchCreate = ({
  label,
  value,
  onChange,
  onReset,
  onSearch,
  onCreate,
  options = [],
  helperText,
  getOptionLabel,
  isOptionEqualToValue,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = async (event, newInputValue) => {
    setInputValue(newInputValue);
    if (onSearch) {
      setLoading(true);
      await onSearch(newInputValue);
      setLoading(false);
    }
  };

  const getFilteredOptions = () => {
    if (!inputValue) return options;

    // Add "Create" option if input is not empty
    return [
      ...options,
      {
        inputValue,
        name: `+ Vytvoriť "${inputValue}"`,
        isCreateOption: true,
      },
    ];
  };

  const handleChange = async (event, newValue) => {
    if (newValue?.isCreateOption) {
      // Handle creation
      try {
        await onCreate(inputValue);
      } catch (error) {
        console.error("Error creating new option:", error);
      }
    } else {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={getFilteredOptions()}
      getOptionLabel={(option) =>
        option?.isCreateOption ? option.name : getOptionLabel(option)
      }
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loading}
      filterOptions={(options) => options}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          helperText={helperText}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoselectFetchCreate;

import React from "react";
import { Box, Typography, Paper, Divider, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PaymentConfirmation = ({ orderData }) => {
  if (!orderData) return null;

  const calculateTotalWithDelivery = () => {
    const orderTotal = parseFloat(orderData.total_amount);
    const deliveryPrice = orderData.shipping_provider
      ? parseFloat(orderData.shipping_provider.price)
      : 0;
    const threshold = orderData.shipping_provider
      ? parseFloat(orderData.shipping_provider.treshold)
      : 0;

    return orderTotal < threshold ? orderTotal + deliveryPrice : orderTotal;
  };

  const total_amount = calculateTotalWithDelivery();
  const order_number = orderData.order_number;

  return (
    <Box className="max-w-screen-xl mx-auto px-4 py-8">
      <Paper sx={{ p: 4, maxWidth: 800, mx: "auto" }}>
        <Box display="flex" alignItems="center" gap={2} mb={4}>
          <CheckCircleIcon color="success" sx={{ fontSize: 40 }} />
          <Typography variant="h5">
            Vaša objednávka č. {order_number} čaká na zaplatenie
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Platobné údaje
        </Typography>
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} sm={4}>
            <Typography color="text.secondary">Suma:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{total_amount.toFixed(2)} EUR</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography color="text.secondary">
              Číslo účtu v tvare IBAN:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>SK1234567890123456789012</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography color="text.secondary">Variabilný symbol:</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Typography>{order_number}</Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom>
          Údaje spoločnosti
        </Typography>
        <Box sx={{ mb: 4 }}>
          <Typography>Bilionbuy International s.r.o.</Typography>
          <Typography>Farbiarska 53/29</Typography>
          <Typography>064 01 Stará Ľubovňa</Typography>
          <Typography>Slovenská republika</Typography>
          <Typography>IČO: 56190999</Typography>
          <Typography>DIČ: 2122237392</Typography>
          <Typography>IČ DPH: SK2122237392</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Typography color="error" sx={{ mb: 4 }}>
          V prípade neuhradenia tejto objednávky do 3 dní, sa táto objednávka
          automaticky stornuje.
        </Typography>

        <Typography variant="h6" gutterBottom>
          Garancia spokojnosti
        </Typography>
        <Typography>
          Vaša spokojnosť je pre nás prvoradá, preto, ak nedodržíme dobu
          dodania, získate od nás ako ospravedlnenie 5 EUR bonus na ďalší nákup
        </Typography>
      </Paper>
    </Box>
  );
};

export default PaymentConfirmation;

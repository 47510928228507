import {
  Button,
  Link,
  Spinner,
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
} from "@nextui-org/react";
import { motion } from "framer-motion";
import CreateReview from "./CreateReview";
import api from "../services/api";
import React, { useState, useEffect } from "react";
import { Select, SelectItem } from "@nextui-org/select";
import { useNavigate } from "react-router-dom";
import { MdOutlineStar } from "react-icons/md";
import ImageGallery from "react-image-gallery";
import { Helmet } from "react-helmet-async";
import Translate from "./TranslationAPI";
import { useTranslation } from "react-i18next";
import OfferCard from "./OfferCard";
import { useAdultContent } from "../providers/AdultContentProvider";
import { useCart } from "../contexts/CartContext";
import { FALLBACK_IMAGE } from "../data/common";

// Custom CartIcon component
const CartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 inline-block ml-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const processCategories = (product_category) => {
  return product_category
    ?.replace("Heureka.cz |", "")
    .replace("Heureka.sk |", "")
    .replace(" |", " ")
    .replace(" |", " > ")
    .split(/[\|>]/)
    .map((category) => category.trim());
};

export default function Product({
  id,
  productData,
  onReadMoreClick,
  onCompareClick,
  handleRedirectClick,
  bestOffers,
  bestCashbackOffers,
  isLoading,
}) {
  const { t } = useTranslation();
  const { requestAgeConfirmation } = useAdultContent();
  const [cats, setCats] = useState([]);
  const [variants, setVariants] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [isVerifying, setIsVerifying] = useState(false);

  // Add cart context
  const { addToCart } = useCart();

  // Add helper function to handle Bilionbuy shop
  const handleBilionbuyClick = (shop) => {
    addToCart({
      id: shop.id,
      name: productData.product_name,
      price_vat: shop.price_vat || shop.price || "0",
      img_url: productData.product_image_url || FALLBACK_IMAGE,
      shop_name: "Bilionbuy",
      quantity: 1,
    });
  };

  useEffect(() => {
    if (productData?.adult_content) {
      setIsVerifying(true);
      requestAgeConfirmation(() => {
        setCats(
          productData?.category_path
            ? processCategories(productData.category_path)
            : []
        );
        setIsVerifying(false);
      });
    } else {
      setCats(
        productData?.category_path
          ? processCategories(productData.category_path)
          : []
      );
    }
  }, [productData, requestAgeConfirmation]);

  if (!productData || isVerifying) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <Spinner />
      </div>
    );
  }

  const handleSelectionChange = (selectedKeys) => {
    const selectedKey = selectedKeys[0]; // Assuming single selection
    const variant = variants.find((variant) => variant.key === selectedKey);

    if (!variant) {
      console.error("Selected variant not found in variants:", selectedKey);
      return;
    }

    navigate(`/view-product/${variant.id}`);
  };

  // Prepare the images for the gallery
  const images = [
    ...(productData?.product_image_url
      ? [
          {
            original: productData.product_image_url,
            thumbnail: productData.product_image_url,
            originalOnError: (e) => {
              e.target.onerror = null;
              e.target.src = FALLBACK_IMAGE;
            },
            thumbnailOnError: (e) => {
              e.target.onerror = null;
              e.target.src = FALLBACK_IMAGE;
            },
          },
        ]
      : []),
    ...(Array.isArray(productData?.additional_photos)
      ? productData.additional_photos.map((photo) => ({
          original: photo,
          thumbnail: photo,
          originalOnError: (e) => {
            e.target.onerror = null;
            e.target.src = FALLBACK_IMAGE;
          },
          thumbnailOnError: (e) => {
            e.target.onerror = null;
            e.target.src = FALLBACK_IMAGE;
          },
        }))
      : []),
  ];

  return (
    <>
      {/* Helmet for SEO */}
      <Helmet>
        <title>{`${productData.product_name} - Buy at Best Price | Bilionbuy`}</title>
        <meta
          name="description"
          content={`Get the best deal on ${productData.product_name}. Compare prices, read reviews, and make an informed purchase!`}
        />
        <meta
          name="keywords"
          content={`${productData.product_name}, buy ${productData.product_name}, best price, product comparison, reviews`}
        />
        <meta
          property="og:title"
          content={`${productData.product_name} - Buy at Best Price | Bilionbuy`}
        />
        <meta
          property="og:description"
          content={`Check out ${productData.product_name} on Bilionbuy. Compare prices and see user ratings and reviews.`}
        />
        <meta
          property="og:url"
          content={`https://www.bilionbuy.com/view-product/${id}`}
        />
        <meta property="og:type" content="product" />
        <meta
          property="og:image"
          content={productData.product_image_url || FALLBACK_IMAGE}
        />
      </Helmet>

      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        <div className="bg-white pb-8">
          <div className="max-w-screen-xl mx-auto">
            <div className="pt-8 px-4 xl:px-0">
              {/* Breadcrumbs */}
              <nav aria-label="Breadcrumb" className="text-lightgray text-sm">
                {cats?.map((category, index) => (
                  <React.Fragment key={index}>
                    <Link
                      href={`/category/${category}`}
                      className="hover:underline"
                    >
                      <Translate text={category} />
                    </Link>
                    {index < cats.length - 1 && " > "}
                  </React.Fragment>
                ))}
              </nav>

              <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 pt-8">
                {/* Product Image and Details - Now spans 1 column */}
                <div className="bg-white rounded-lg shadow-lg p-4 h-full">
                  {/* Product Image */}
                  <div className="flex flex-col h-full">
                    <div className="flex items-center justify-center mb-4">
                      <img
                        src={productData.product_image_url}
                        alt={productData.product_name}
                        className="w-full h-[200px] object-contain cursor-pointer"
                        onClick={onOpen}
                        onError={(e) => {
                          e.target.onerror = null; // Prevent infinite loop
                          e.target.src = FALLBACK_IMAGE;
                        }}
                      />
                    </div>

                    {/* Product Details */}
                    <div className="text-center flex-grow">
                      <h1 className="text-xl font-bold text-gray-900 mb-4">
                        <Translate text={productData.product_name} />
                      </h1>

                      {/* Rating Section */}
                      <div className="flex justify-center gap-2 mb-4">
                        {[1, 2, 3, 4, 5].map((value) => (
                          <MdOutlineStar
                            key={value}
                            className={
                              productData.rating >= value
                                ? "text-primary text-2xl"
                                : "text-lightgray text-2xl"
                            }
                          />
                        ))}
                      </div>

                      <div className="flex flex-col gap-2 mb-4">
                        {productData.total_users_recommend > 0 && (
                          <span className="bg-success-50 px-3 py-1 rounded-full text-sm">
                            {productData.total_users_recommend}{" "}
                            {t("zákazníkov odporúča produkt")}
                          </span>
                        )}

                        {productData.total_users_not_recommend > 0 && (
                          <span className="bg-danger-50 px-3 py-1 rounded-full text-sm">
                            {productData.total_users_not_recommend}{" "}
                            {t("zákazníkov neodporúča produkt")}
                          </span>
                        )}
                      </div>

                      {/* Variants Selector */}
                      {variants.length > 0 && (
                        <Select
                          label="Varianty"
                          className="w-full mb-4"
                          variant="bordered"
                          placeholder={productData.product_name}
                          onSelectionChange={handleSelectionChange}
                        >
                          {variants.map((variant) => (
                            <SelectItem key={variant.key}>
                              {variant.product_name}
                            </SelectItem>
                          ))}
                        </Select>
                      )}
                    </div>

                    {/* Review Button and Additional Buttons */}
                    <div className="mt-auto flex flex-wrap  gap-2 items-center">
                      <a
                        className="border-1 p-2 text-white font-bold bg-blue px-4 rounded-3xl text-xs hover:cursor-pointer"
                        onClick={onCompareClick}
                      >
                        {t("Porovnať")}
                      </a>
                      <CreateReview productId={id} productData={productData} />
                      <a
                        className="border-1 p-2 text-white font-bold bg-blue px-4 rounded-3xl text-xs hover:cursor-pointer"
                        onClick={onReadMoreClick}
                      >
                        {t("Viac")}
                      </a>
                    </div>
                  </div>
                </div>

                {/* Best Overall Offer */}
                {bestOffers?.[0] && (
                  <div className="bg-white rounded-lg shadow-lg p-4 h-full">
                    <div className="flex flex-col h-full">
                      <h2 className="font-semibold text-lg mb-4 text-center">
                        {t("Odporúčaná ponuka")}
                      </h2>
                      <div className="flex items-center justify-center mb-4">
                        <img
                          src={bestOffers[0].shop_image}
                          alt={bestOffers[0].shop_name}
                          className="w-full h-[200px] object-contain"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = FALLBACK_IMAGE;
                          }}
                        />
                      </div>
                      <div className="text-center flex-grow">
                        <h3 className="font-semibold mb-2">
                          {bestOffers[0].shop_name}
                        </h3>
                        <p className="text-xl font-bold text-blue mb-2">
                          {bestOffers[0].price_vat} {bestOffers[0].currency}
                        </p>
                        {bestOffers[0].computed_cashback ? (
                          <span className="bg-success-50 text-success-700 px-3 py-1 rounded-full text-sm mb-4 font-semibold">
                            Cashback{" "}
                            {(
                              bestOffers[0].computed_cashback *
                              parseFloat(
                                bestOffers[0].price_vat.replace(/[^\d.]/g, "")
                              )
                            ).toFixed(2)}{" "}
                            {bestOffers[0].currency?.replace("EUR", "€")}
                          </span>
                        ) : null}
                      </div>
                      <div className="text-center flex-grow py-2">
                        <span className="bg-success-50 text-success-700 px-3 py-1 rounded-full text-sm mb-4 font-semibold">
                          {bestOffers[0].computed_points}
                        </span>
                      </div>
                      <Button
                        className={`w-full ${
                          bestOffers[0].shop_name === "Bilionbuy"
                            ? "bg-pink text-white"
                            : "bg-blue text-white"
                        }`}
                        onClick={() =>
                          bestOffers[0].shop_name === "Bilionbuy"
                            ? handleBilionbuyClick(bestOffers[0])
                            : handleRedirectClick(
                                bestOffers[0].url,
                                bestOffers[0].id,
                                bestOffers[0].shop_name
                              )
                        }
                      >
                        {bestOffers[0].shop_name === "Bilionbuy" ? (
                          <>
                            {t("Do košíka")} <CartIcon />
                          </>
                        ) : (
                          <>{t("Do obchodu")} →</>
                        )}
                      </Button>
                    </div>
                  </div>
                )}

                {/* Best Cashback Offer */}
                {bestCashbackOffers?.[0] ? (
                  <div className="bg-white rounded-lg shadow-lg p-4 h-full">
                    <div className="flex flex-col h-full">
                      <h2 className="font-semibold text-lg mb-4 text-center">
                        {t("Najväčší cashback")}
                      </h2>
                      <div className="flex items-center justify-center mb-4">
                        <img
                          src={bestCashbackOffers[0].shop_image}
                          alt={bestCashbackOffers[0].shop_name}
                          className="w-full h-[200px] object-contain"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = FALLBACK_IMAGE;
                          }}
                        />
                      </div>
                      <div className="text-center flex-grow">
                        <h3 className="font-semibold mb-2">
                          {bestCashbackOffers[0].shop_name}
                        </h3>
                        <p className="text-xl font-bold text-blue mb-2">
                          {bestCashbackOffers[0].price_vat}{" "}
                          {bestCashbackOffers[0].currency}
                        </p>
                        <span className="bg-success-50 text-success-700 px-3 py-1 rounded-full text-sm mb-4 font-semibold">
                          Cashback{" "}
                          {(
                            bestCashbackOffers[0].computed_cashback *
                            parseFloat(
                              bestCashbackOffers[0].price_vat.replace(
                                /[^\d.]/g,
                                ""
                              )
                            )
                          ).toFixed(2)}{" "}
                          {bestCashbackOffers[0].currency?.replace("EUR", "€")}
                        </span>
                      </div>
                      <div className="text-center flex-grow py-2">
                        <span className="bg-success-50 text-success-700 px-3 py-1 rounded-full text-sm mb-4 font-semibold">
                          {bestCashbackOffers[0].computed_points}
                        </span>
                      </div>

                      <Button
                        className={`w-full ${
                          bestCashbackOffers[0].shop_name === "Bilionbuy"
                            ? "bg-pink text-white"
                            : "bg-blue text-white"
                        }`}
                        onClick={() =>
                          bestCashbackOffers[0].shop_name === "Bilionbuy"
                            ? handleBilionbuyClick(bestCashbackOffers[0])
                            : handleRedirectClick(
                                bestCashbackOffers[0].url,
                                bestCashbackOffers[0].id,
                                bestCashbackOffers[0].shop_name
                              )
                        }
                      >
                        {bestCashbackOffers[0].shop_name === "Bilionbuy" ? (
                          <>
                            {t("Do košíka")} <CartIcon />
                          </>
                        ) : (
                          <>{t("Do obchodu")} →</>
                        )}
                      </Button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Fullscreen Image Modal */}
        <Modal size="full" isOpen={isOpen} onClose={onClose}>
          <ModalContent>
            {() => (
              <ModalBody>
                {/* Use ImageGallery for fullscreen view */}
                <ImageGallery items={images} />
              </ModalBody>
            )}
          </ModalContent>
        </Modal>
      </motion.div>
    </>
  );
}

import { Image, Button, Link, Skeleton } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import Translate from "./TranslationAPI";
import { withAdultContent } from "./hoc/withAdultContent";
import { useAdultContent } from "../providers/AdultContentProvider";

const FALLBACK_IMAGE =
  "https://cdn.shopify.com/s/files/1/0533/2089/files/placeholder-images-image_large.png?v=1530129081";

const SeasonalCard = ({ product, type, isLoading }) => {
  const { t } = useTranslation();
  const typeColorMapping = {
    product: "bg-primary",
    food: "bg-green",
    travel: "bg-orange",
  };

  const colorClass = typeColorMapping[type] || "bg-gray-500";

  // Function to clean HTML tags and truncate text
  const cleanAndTruncateText = (html, maxLength = 38) => {
    if (html == null) return "";
    const cleanText = html.replace(/<[^>]*>/g, "");
    return cleanText.length > maxLength
      ? cleanText.substring(0, maxLength) + "..."
      : cleanText;
  };

  if (isLoading) {
    return (
      <div className="px-6 mb-6 flex justify-center items-center">
        <div className="shadow-md border-1 relative rounded-lg flex flex-col gap-4 transition duration-300 min-w-48">
          <div className="relative h-32">
            <Skeleton className="absolute inset-0 rounded-xl" />
          </div>

          {/* Cashback badge skeleton */}
          <div className="absolute top-2 left-1 z-10">
            <Skeleton className="h-6 w-20 rounded-full" />
          </div>

          {/* Shop logo skeleton */}
          <div className="absolute top-3 right-2 z-10">
            <Skeleton className="h-12 w-12 rounded-lg" />
          </div>

          <div className="flex flex-col gap-0 px-4">
            {/* Price skeleton */}
            <Skeleton className="h-4 w-16 mb-2" />

            {/* Label skeleton */}
            <Skeleton className="h-5 w-24 mb-2" />

            {/* Title skeleton */}
            <Skeleton className="h-4 w-full mb-6" />
          </div>

          {/* Buttons skeleton */}
          <div className="absolute -bottom-6 w-full flex flex-row justify-center gap-1">
            <Skeleton className="h-8 w-24 rounded-full" />
            <Skeleton className="h-8 w-24 rounded-full" />
          </div>
        </div>
      </div>
    );
  }

  if (!product) return null;

  return (
    <>
      <div className="px-6 mb-6 flex justify-center items-center">
        <div className="shadow-md border-1 relative rounded-lg flex flex-col gap-4 transition duration-300 min-w-48">
          <div className="rounded-xl relative flex justify-center items-center">
            <img
              src={
                product.linkedshop?.img_url ||
                product.product?.product_image_url
              }
              alt={product.product?.product_name}
              className="w-full h-32 rounded-x object-cover"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = FALLBACK_IMAGE;
              }}
            />
          </div>
          <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl absolute top-2 z-10 left-1 scale-75">
            {product.discount
              ? "- " + product.discount + "%"
              : product.shop_max_cashback + "% cashback"}
          </a>
          <a className="bg-white text-white font-semibold p-1 rounded-lg absolute top-3 z-10 right-2">
            <img
              src={product.shop_logo_url}
              alt={product.shop_name}
              className="w-12"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = FALLBACK_IMAGE;
              }}
            />
          </a>

          <div className="flex flex-col gap-0 px-4">
            <div className="font-bold text-xs">
              {product.offer_price.replace("EUR", "€")}
            </div>
            <div>
              <span className="p-1 rounded-sm bg-black text-white text-[10px] font-semibold uppercase">
                {t("Sezónna ponuka")}
              </span>
            </div>

            <div className="font-bold text-[#6B6B70] line-clamp-2 text-sm pb-2">
              {cleanAndTruncateText(product.product?.product_name)}
            </div>
          </div>

          <div className=" w-full flex flex-row justify-center gap-1 text-xs scale-90 pb-2">
            <Button
              className={`${colorClass} text-white rounded-sm px-6`}
              as={Link}
              href={product.final_url || ""}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("Do obchodu")}
            </Button>
            <Button
              className={`bg-green text-white rounded-sm px-6`}
              as={Link}
              href={"/view-product/" + product.product?.slug}
            >
              {t("Porovnaj")}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default withAdultContent(SeasonalCard);

import React from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import PaymentSuccess from "../components/eshop/PaymentSuccess";

const PaymentSuccessPage = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <BasicNavbar />
      <main className="flex-grow">
        <PaymentSuccess />
      </main>
      <Footer />
    </div>
  );
};

export default PaymentSuccessPage;

import React from "react";
import { Card, Button, Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@nextui-org/react";

const CartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 inline-block ml-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);

const BilionbuyItemCard = ({ product, onButtonClick }) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick({
        id: product.id,
        name: product.product.product_name,
        price_vat: product.price_vat,
        img_url: product.product.product_image_url,
        shop_name: product.shop_name,
      });
    }
  };
  // Function to clean HTML tags and truncate text
  const cleanAndTruncateText = (html, maxLength = 32) => {
    if (html == null) return "";
    const cleanText = html.replace(/<[^>]*>/g, "");
    return cleanText.length > maxLength
      ? cleanText.substring(0, maxLength) + "..."
      : cleanText;
  };

  return (
    <div className="px-6 mb-6 flex justify-center items-center">
      <div className="shadow-md border-1 relative rounded-lg flex flex-col gap-4 transition duration-300 min-w-48">
        <div className="rounded-xl relative flex justify-center items-center">
          <img
            src={product.product.product_image_url}
            alt={product.product.product_name}
            className="w-full h-32 rounded-x object-cover"
          />
        </div>
        <Tooltip
          content={t("Cashback, ktorý získate pri nákupe tohto produktu")}
        >
          <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl absolute top-2 z-10 left-1 scale-75">
            {product.direct_cashback ? product.direct_cashback : null}
          </a>
        </Tooltip>
        <Tooltip content={t("Body, ktoré získate pri nákupe tohto produktu")}>
          <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl absolute top-2 z-10 right-1 scale-75">
            {product.points ? product.points : null}
          </a>
        </Tooltip>
        {/* {product.stock && (
          <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl absolute top-2 z-10 left-1 scale-75">
            {t("Na sklade")}: {product.stock_count}
          </a>
        )} */}

        <div className="flex flex-col gap-0 px-4">
          <div className="font-bold text-xs">
            {product.price_vat ? product.price_vat.replace(".", ",") : "N/A"} €
          </div>

          {/* {product.delivery_date && (
            <div>
              <span className="p-1 rounded-sm bg-orange-500 text-white text-[10px] font-semibold uppercase">
                {t("Dodanie")}: {getDeliveryText(product.delivery_date)}
              </span>
            </div>
          )} */}

          <div className="font-bold text-[#6B6B70] line-clamp-2 text-sm pb-2">
            {cleanAndTruncateText(product.product.product_name)}
          </div>
        </div>

        <div className="w-full flex flex-row justify-center gap-1 text-xs scale-90 pb-2">
          <Button
            className="bg-pink text-white rounded-sm px-6"
            onClick={handleClick}
          >
            {t("Do košíka")} <CartIcon />
          </Button>
          <Button
            className="bg-green text-white rounded-sm px-6"
            as={Link}
            href={"/view-product/" + product.product.slug}
          >
            {t("Zobraziť")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BilionbuyItemCard;

import React, { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useCart } from "../contexts/CartContext";
import { Button, Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { cartService } from "../services/cartService";

const CartPreview = () => {
  const { t } = useTranslation();
  const {
    cartItems,
    isCartOpen,
    setIsCartOpen,
    getCartTotal,
    isLoading,
    error,
    fetchCart,
  } = useCart();

  useEffect(() => {
    let mounted = true;

    if (isCartOpen && mounted) {
      fetchCart();
    }

    return () => {
      mounted = false;
    };
  }, [isCartOpen]);

  if (!isCartOpen) return null;

  const handleRemoveItem = async (itemId) => {
    try {
      await cartService.removeItem(itemId);
      await fetchCart();
    } catch (err) {
      console.error("Failed to remove item:", err);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      className="absolute right-0 top-12 z-50 w-96 max-h-[80vh] overflow-y-auto bg-white shadow-xl rounded-lg"
    >
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-4">{t("Košík")}</h3>

        {error && <div className="text-red-500 mb-4">{error}</div>}

        {isLoading ? (
          <div className="flex justify-center py-4">
            <Spinner size="sm" color="primary" />
          </div>
        ) : cartItems.length === 0 ? (
          <p className="text-gray-500">{t("Košík je prázdny")}</p>
        ) : (
          <>
            <div className="space-y-4 mb-4">
              {cartItems.map((item) => (
                <div
                  key={item.id}
                  className="flex justify-between items-center"
                >
                  <div className="flex items-center space-x-4">
                    <img
                      src={item.img_url}
                      alt={item.name}
                      className="w-16 h-16 object-cover rounded"
                    />
                    <div>
                      <p className="font-medium">{item.name}</p>
                      <p className="text-sm text-gray-500">
                        {item.price_vat} € x {item.quantity}
                      </p>
                      <p className="text-xs text-gray-400">
                        {t("Body")}: {(item.points * item.quantity).toFixed(3)}
                      </p>
                      <p className="text-xs text-green-600">
                        {t("Cashback")}:{" "}
                        {(item.cashback * item.quantity).toFixed(2)} €
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => handleRemoveItem(item.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
            <div className="border-t pt-4">
              <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-600">{t("Bez DPH")}:</span>
                <span className="text-sm">
                  {(getCartTotal().total - getCartTotal().vat).toFixed(2)} €
                </span>
              </div>
              <div className="flex justify-between mb-4">
                <span className="text-sm text-gray-600">{t("DPH")}:</span>
                <span className="text-sm">
                  {getCartTotal().vat.toFixed(2)} €
                </span>
              </div>
              <div className="flex justify-between mb-4">
                <span className="font-semibold">{t("Celkom")}:</span>
                <span className="font-semibold">
                  {getCartTotal().total.toFixed(2)} €
                </span>
              </div>
              <Link to="/cart">
                <Button
                  color="primary"
                  className="w-full"
                  onClick={() => setIsCartOpen(false)}
                >
                  {t("Prejsť do košíka")}
                </Button>
              </Link>
            </div>
          </>
        )}
      </div>
    </motion.div>
  );
};

export default CartPreview;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  LinearProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import api from "../../../services/api";

const COUNTRIES = [
  { code: "SK", name: "Slovensko" },
  { code: "CZ", name: "Česká republika" },
];

const countryFlags = {
  SK: "🇸🇰",
  CZ: "🇨🇿",
};

export default function EshopProductImport() {
  // File upload states
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [results, setResults] = useState(null);

  // Provider states
  const [providers, setProviders] = useState([]);
  const [open, setOpen] = useState(false);
  const [newProvider, setNewProvider] = useState({
    name: "",
    url: "",
    description: "",
    country: "",
  });
  const [providerError, setProviderError] = useState(null);
  const [providerSuccess, setProviderSuccess] = useState(null);

  // Add new state for delete dialog
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    providerId: null,
  });

  useEffect(() => {
    fetchProviders();
  }, []);

  const fetchProviders = async () => {
    try {
      const response = await api.get("/eshop/providers-data/");
      setProviders(response.data);
    } catch (error) {
      setProviderError("Chyba pri načítaní poskytovateľov");
    }
  };

  // Provider management functions
  const handleOpen = () => {
    setOpen(true);
    setProviderError(null);
  };

  const handleClose = () => {
    setOpen(false);
    setNewProvider({ name: "", url: "", description: "", country: "" });
    setProviderError(null);
  };

  const handleCreate = async () => {
    try {
      await api.post("/eshop/providers-data/", newProvider);
      setProviderSuccess("Poskytovateľ bol úspešne vytvorený");
      handleClose();
      fetchProviders();
      setTimeout(() => setProviderSuccess(null), 3000);
    } catch (error) {
      setProviderError(
        error.response?.data?.message || "Chyba pri vytváraní poskytovateľa"
      );
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteDialog({
      open: true,
      providerId: id,
    });
  };

  const handleDeleteClose = () => {
    setDeleteDialog({
      open: false,
      providerId: null,
    });
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/eshop/providers-data/${deleteDialog.providerId}/`);
      setProviderSuccess("Poskytovateľ bol úspešne vymazaný");
      fetchProviders();
      setTimeout(() => setProviderSuccess(null), 3000);
    } catch (error) {
      setProviderError(
        error.response?.data?.message || "Chyba pri mazaní poskytovateľa"
      );
    }
    handleDeleteClose();
  };

  // File upload functions
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "text/csv" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel")
    ) {
      setFile(selectedFile);
      setError(null);
    } else {
      setError("Prosím vyberte CSV alebo Excel súbor");
      setFile(null);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);
    setUploading(true);
    setProgress(0);
    setError(null);
    setSuccess(false);

    try {
      const response = await api.post("/eshop/import-products/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      });

      setSuccess(true);
      setResults(response.data);
      setFile(null);
    } catch (error) {
      setError(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Nastala chyba pri nahrávaní súboru"
      );
    } finally {
      setUploading(false);
    }
  };

  const CountryWithFlag = ({ countryCode }) => {
    return (
      <Box display="flex" alignItems="center" gap={1}>
        <span>{countryFlags[countryCode]}</span>
        <span>
          {COUNTRIES.find((c) => c.code === countryCode)?.name || countryCode}
        </span>
      </Box>
    );
  };

  return (
    <Box>
      {/* Providers Section */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <h2>Poskytovatelia</h2>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleOpen}
        >
          Pridať poskytovateľa
        </Button>
      </Box>

      {providerError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {providerError}
        </Alert>
      )}
      {providerSuccess && (
        <Alert severity="success" sx={{ mb: 2 }}>
          {providerSuccess}
        </Alert>
      )}

      <TableContainer component={Paper} sx={{ mb: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Názov</TableCell>
              <TableCell>Krajina</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>Popis</TableCell>
              <TableCell>Posledná aktualizácia</TableCell>
              <TableCell>Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {providers.map((provider) => (
              <TableRow key={provider.id}>
                <TableCell>{provider.name}</TableCell>
                <TableCell>
                  <CountryWithFlag countryCode={provider.country} />
                </TableCell>
                <TableCell>{provider.url || "-"}</TableCell>
                <TableCell>{provider.description || "-"}</TableCell>
                <TableCell>
                  {provider.updated_at
                    ? new Date(provider.updated_at).toLocaleString()
                    : "-"}
                </TableCell>
                <TableCell>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteClick(provider.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Divider sx={{ my: 4 }} />

      {/* File Upload Section */}
      <Box>
        <h2>Import produktov</h2>
        <input
          accept=".csv,.xls,.xlsx"
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={handleFileChange}
          disabled={uploading}
        />
        <Box display="flex" flexDirection="column" gap={2}>
          <label htmlFor="raised-button-file">
            <Button
              variant="outlined"
              component="span"
              startIcon={<CloudUploadIcon />}
              // disabled={uploading}
              disabled
            >
              Vybrať súbor
            </Button>
          </label>

          {file && (
            <Typography variant="body2">Vybraný súbor: {file.name}</Typography>
          )}

          {uploading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress variant="determinate" value={progress} />
              <Typography variant="body2" color="text.secondary">
                {progress}%
              </Typography>
            </Box>
          )}

          {error && <Alert severity="error">{error}</Alert>}

          {success && (
            <Alert severity="success">
              Súbor bol úspešne spracovaný
              {results && (
                <Box mt={1}>
                  <Typography variant="body2">
                    Vytvorené produkty: {results.created}
                    <br />
                    Aktualizované produkty: {results.updated}
                  </Typography>
                  {results.errors?.length > 0 && (
                    <>
                      <Typography variant="body2" color="error" mt={1}>
                        Chyby pri spracovaní:
                      </Typography>
                      <ul style={{ margin: 0, paddingLeft: 20 }}>
                        {results.errors.map((error, index) => (
                          <li key={index}>
                            <Typography variant="body2" color="error">
                              {error}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </Box>
              )}
            </Alert>
          )}

          <Button
            variant="contained"
            onClick={handleUpload}
            disabled={!file || uploading}
          >
            Nahrať a odoslať na spracovanie
          </Button>
        </Box>
      </Box>

      {/* Provider Creation Dialog */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Pridať nového poskytovateľa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Názov"
            fullWidth
            required
            value={newProvider.name}
            onChange={(e) =>
              setNewProvider({ ...newProvider, name: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense" required>
            <InputLabel>Krajina</InputLabel>
            <Select
              value={newProvider.country}
              label="Krajina"
              onChange={(e) =>
                setNewProvider({ ...newProvider, country: e.target.value })
              }
            >
              {COUNTRIES.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  <CountryWithFlag countryCode={country.code} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="URL"
            fullWidth
            value={newProvider.url}
            onChange={(e) =>
              setNewProvider({ ...newProvider, url: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="Popis"
            fullWidth
            multiline
            rows={3}
            value={newProvider.description}
            onChange={(e) =>
              setNewProvider({ ...newProvider, description: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zrušiť</Button>
          <Button onClick={handleCreate} variant="contained">
            Vytvoriť
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete confirmation dialog */}
      <Dialog open={deleteDialog.open} onClose={handleDeleteClose}>
        <DialogTitle>Potvrdiť vymazanie</DialogTitle>
        <DialogContent>
          <Typography>Naozaj chcete vymazať tohto poskytovateľa?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Zrušiť</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Vymazať
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

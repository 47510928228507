import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Alert,
} from "@mui/material";
import api from "../services/api";
import ReactCountryFlag from "react-country-flag";

export default function BigBuyProductSearch() {
  const { t } = useTranslation();
  const [productId, setProductId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState(null);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    if (!productId) return;

    setIsLoading(true);
    setError(null);
    setProductData(null);

    try {
      const response = await api.get(`bigbuy/product/${productId}`);
      setProductData(response.data);
    } catch (err) {
      setError(
        err.response?.data?.message || "Chyba pri načítaní údajov o produkte"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ maxWidth: "100%", margin: "auto", p: 2 }}>
      <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
        <TextField
          fullWidth
          label="ID produktu"
          value={productId}
          onChange={(e) => setProductId(e.target.value)}
          placeholder="Zadajte ID produktu"
          size="small"
        />
        <Button
          variant="contained"
          onClick={handleSearch}
          disabled={isLoading || !productId}
        >
          Vyhľadať
        </Button>
      </Box>

      {isLoading && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            my: 4,
          }}
        >
          <Typography color="primary">
            Čakajte, načítanie môže trvať dlhšie
          </Typography>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}

      {productData && (
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              {/* Product Details */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Detaily produktu
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography>
                    <strong>ID:</strong> {productData.product.id}
                  </Typography>
                  <Typography>
                    <strong>SKU:</strong> {productData.product.sku}
                  </Typography>
                  <Typography>
                    <strong>EAN13:</strong> {productData.product.ean13}
                  </Typography>
                  <Typography>
                    <strong>Stav:</strong> {productData.product.condition}
                  </Typography>
                  <Typography>
                    <strong>Hmotnosť:</strong> {productData.product.weight}g
                  </Typography>
                  <Typography>
                    <strong>Rozmery:</strong> {productData.product.height}x
                    {productData.product.width}x{productData.product.depth} cm
                  </Typography>
                </Box>
              </Grid>

              {/* Pricing & Stock */}
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom>
                  Ceny a skladové zásoby
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Typography>
                    <strong>Veľkoobchodná cena: </strong>
                    {productData.pricing.wholesale_price} €
                  </Typography>
                  <Typography>
                    <strong>Maloobchodná cena: </strong>
                    {productData.pricing.retail_price} €
                  </Typography>
                  <Typography>
                    <strong>Cena v obchodoch: </strong>
                    {productData.pricing.inshops_price} €
                  </Typography>
                  <Typography>
                    <strong>Sadzba DPH: </strong> {productData.pricing.tax_rate}
                    %
                  </Typography>
                  <Typography>
                    <strong>Skladom:</strong> {productData.stock.available}
                  </Typography>
                  <Typography>
                    <strong>Doba spracovania: </strong>{" "}
                    {productData.stock.handling_days} dní
                  </Typography>
                </Box>
              </Grid>

              {/* Shipping Information */}
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Informácie o doprave
                </Typography>
                <Grid container spacing={2}>
                  {Object.entries(
                    productData.shipping.shipping_costs_by_country
                  ).map(([country, info]) => (
                    <Grid item xs={12} sm={6} md={3} key={country}>
                      <Card variant="outlined">
                        <CardContent>
                          <Typography variant="subtitle1" gutterBottom>
                            {country} <ReactCountryFlag countryCode={country} />
                          </Typography>
                          <Typography>
                            <strong>Cena:</strong> {info.cost} €
                          </Typography>
                          <Typography>
                            <strong>Dopravca:</strong> {info.carrier}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import ItemCard from "./ItemCard";
import api from "../services/api";
import CustomCarousel from "./CustomCarousel";
import { Skeleton } from "@nextui-org/react";
import SeasonalCard from "./SeasonalCard";
import BilionbuyItemCard from "./BilionbuyItemCard";
import { FALLBACK_IMAGE } from "../data/common";

const ProductSlider = ({
  title,
  text,
  type,
  fetchCall,
  initialData,
  isLoading,
  onButtonClick,
}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(!initialData);

  useEffect(() => {
    if (initialData) {
      console.log("Initial data received:", initialData);
      setProducts(initialData);
      setLoading(false);
    } else if (fetchCall) {
      const fetchProducts = async () => {
        try {
          setLoading(true);
          const response = await api.get(fetchCall);
          const safeData = Array.isArray(response.data) ? response.data : [];
          setProducts(safeData);
        } catch (error) {
          console.error("Error fetching products:", error);
          setProducts([]);
        } finally {
          setLoading(false);
        }
      };
      fetchProducts();
    }
  }, [fetchCall, initialData]);

  const showLoading = isLoading !== undefined ? isLoading : loading;

  if (showLoading) {
    return (
      <div className="max-w-screen-2xl mx-auto px-4 md:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row justify-between pt-12 pb-6">
          <h2 className="text-4xl font-bold">{title}</h2>
          <p className="text-base font-light max-w-[700px] mt-4 lg:mt-0">
            {text}
          </p>
        </div>
        <CustomCarousel>
          {Array.from({ length: 6 }).map((_, index) =>
            type === "offer-products" ? (
              <SeasonalCard key={index} isLoading={true} />
            ) : (
              <div
                key={index}
                className="w-full bg-white rounded-lg shadow-lg p-4 flex flex-col"
              >
                <div className="relative pb-[100%] mb-4">
                  <Skeleton className="absolute inset-0 rounded-lg" />
                </div>
                <Skeleton className="h-6 w-3/4 mb-2" />
                <Skeleton className="h-4 w-1/2 mb-2" />
                <Skeleton className="h-4 w-2/3 mb-4" />
                <div className="mt-auto">
                  <Skeleton className="h-10 w-full rounded-full" />
                </div>
              </div>
            )
          )}
        </CustomCarousel>
      </div>
    );
  }

  return (
    <div className="max-w-screen-2xl mx-auto px-4 md:px-6 lg:px-8">
      <div className="flex flex-col lg:flex-row justify-between pt-12 pb-6">
        <h2 className="text-4xl font-bold">{title}</h2>
        <p className="text-base font-light max-w-[700px] mt-4 lg:mt-0">
          {text}
        </p>
      </div>

      <CustomCarousel>
        {products.map((item, index) => {
          if (type === "offer-products") {
            return (
              <SeasonalCard
                key={item.id || index}
                product={item}
                type="product"
              />
            );
          } else if (type === "bilionbuy-products") {
            return (
              <BilionbuyItemCard
                key={item.id || index}
                product={item}
                onButtonClick={onButtonClick}
              />
            );
          } else {
            return (
              <ItemCard key={item.id || index} product={item} type={type} />
            );
          }
        })}
      </CustomCarousel>
    </div>
  );
};

export default ProductSlider;

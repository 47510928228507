import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { toast } from "react-toastify";
import api from "../../../services/api";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const initialOfferState = {
  stock: null,
  stock_count: "",
  delivery_date: "",
  delivery: null,
  gift: null,
  price_vat: "",
  price_novat: "",
  extended_warranty: null,
  special_service: null,
  sales_voucher: null,
  cashback: "",
  visibility: true,
};

const BilionBuyOfferModal = ({ isOpen, onClose, product, onUpdate }) => {
  const [editedOffer, setEditedOffer] = useState(initialOfferState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen && product) {
      setEditedOffer({
        price_vat: product.price_vat || "",
        price_novat: product.price_novat || "",
        stock: product.stock || null,
        stock_count: product.stock_count || "",
        delivery_date: product.delivery_date || "",
        delivery: product.delivery || null,
        gift: product.gift || null,
        extended_warranty: product.extended_warranty || null,
        special_service: product.special_service || null,
        sales_voucher: product.sales_voucher || null,
        cashback: product.cashback || "",
        visibility: product.shop_link_visibility ?? true,
      });
    }
  }, [isOpen, product]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const payload = {
        price_vat: editedOffer.price_vat || null,
        price_novat: editedOffer.price_novat || null,
        shop_link_visibility: editedOffer.visibility,
      };

      if (editedOffer.stock) payload.stock = editedOffer.stock;
      if (editedOffer.stock_count)
        payload.stock_count = editedOffer.stock_count;
      if (editedOffer.delivery_date)
        payload.delivery_date = editedOffer.delivery_date;
      if (editedOffer.delivery !== undefined)
        payload.delivery = editedOffer.delivery;
      if (editedOffer.gift) payload.gift = editedOffer.gift;
      if (editedOffer.extended_warranty !== undefined)
        payload.extended_warranty = editedOffer.extended_warranty;
      if (editedOffer.special_service)
        payload.special_service = editedOffer.special_service;
      if (editedOffer.sales_voucher !== undefined)
        payload.sales_voucher = editedOffer.sales_voucher;
      if (editedOffer.cashback) payload.cashback = editedOffer.cashback;

      if (product.price_vat) {
        await api.patch(`/bilionbuy-products/${product.id}/`, payload);
        toast.success("Ponuka obchodu bola úspešne aktualizovaná");
      } else {
        await api.post(`/products/${product.id}/bilionbuy-offer/`, payload);
        toast.success("Ponuka obchodu bola úspešne pridaná");
      }

      onUpdate();
      onClose();
    } catch (error) {
      if (error.response?.status === 400 && error.response?.data?.error) {
        toast.error(error.response.data.error);
      } else {
        console.error("Error saving offer:", error);
        toast.error("Nepodarilo sa uložiť ponuku obchodu");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCashbackChange = (e) => {
    let value = e.target.value.replace(",", ".");
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setEditedOffer((prev) => ({
        ...prev,
        cashback: value,
      }));
    }
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }}>
          Detaily ponuky obchodu
        </Typography>

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Price Section */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Cena (s DPH)"
                name="price_vat"
                value={editedOffer.price_vat}
                onChange={(e) =>
                  setEditedOffer((prev) => ({
                    ...prev,
                    price_vat: e.target.value,
                  }))
                }
                required
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Cena (bez DPH)"
                name="price_novat"
                value={editedOffer.price_novat}
                onChange={(e) =>
                  setEditedOffer((prev) => ({
                    ...prev,
                    price_novat: e.target.value,
                  }))
                }
              />
            </Grid>

            {/* Stock Status Selector */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Stav skladu</InputLabel>
                <Select
                  value={editedOffer.stock}
                  label="Stav skladu"
                  onChange={(e) =>
                    setEditedOffer((prev) => ({
                      ...prev,
                      stock: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={null}>Nešpecifikované</MenuItem>
                  <MenuItem value={1}>Na sklade</MenuItem>
                  <MenuItem value={0}>Vypredané</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Stock Count */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Počet kusov"
                name="stock_count"
                type="number"
                inputProps={{ min: 0, step: 1 }}
                value={editedOffer.stock_count}
                onChange={(e) =>
                  setEditedOffer((prev) => ({
                    ...prev,
                    stock_count: e.target.value
                      ? Math.floor(Number(e.target.value))
                      : "",
                  }))
                }
              />
            </Grid>

            {/* Delivery Date Selector */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Dodacia lehota</InputLabel>
                <Select
                  value={editedOffer.delivery_date}
                  label="Dodacia lehota"
                  onChange={(e) =>
                    setEditedOffer((prev) => ({
                      ...prev,
                      delivery_date: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">Info v obchode</MenuItem>
                  <MenuItem value="0">Skladom</MenuItem>
                  <MenuItem value="3">Do 3 dní</MenuItem>
                  <MenuItem value="7">Do týždňa</MenuItem>
                  <MenuItem value="14">Do 2 týždňov</MenuItem>
                  <MenuItem value="30">Do mesiaca</MenuItem>
                  <MenuItem value="31">Viac ako mesiac</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Gift Selector */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Darček</InputLabel>
                <Select
                  value={editedOffer.gift}
                  label="Darček"
                  onChange={(e) =>
                    setEditedOffer((prev) => ({
                      ...prev,
                      gift: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={null}>Nešpecifikované</MenuItem>
                  <MenuItem value={1}>Áno</MenuItem>
                  <MenuItem value={0}>Nie</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Special Service Selector */}
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel>Špeciálna služba</InputLabel>
                <Select
                  value={editedOffer.special_service}
                  label="Špeciálna služba"
                  onChange={(e) =>
                    setEditedOffer((prev) => ({
                      ...prev,
                      special_service: e.target.value,
                    }))
                  }
                >
                  <MenuItem value={null}>Nešpecifikované</MenuItem>
                  <MenuItem value={1}>Áno</MenuItem>
                  <MenuItem value={0}>Nie</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Cashback */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Cashback"
                name="cashback"
                value={editedOffer.cashback}
                onChange={handleCashbackChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* Visibility Toggle */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editedOffer.visibility}
                    onChange={(e) =>
                      setEditedOffer((prev) => ({
                        ...prev,
                        visibility: e.target.checked,
                      }))
                    }
                  />
                }
                label="Viditeľná ponuka"
              />
            </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={onClose} sx={{ mr: 1 }}>
              Zrušiť
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : "Uložiť"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default BilionBuyOfferModal;

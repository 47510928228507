import { LuDownload } from "react-icons/lu";
import { IoIosArrowBack } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const Materials = ({ onMenuToggle, t }) => {
  const { i18n } = useTranslation();
  const [showInstructions, setShowInstructions] = useState(false);

  const careerPlanDocs = [
    { lang: "SK", label: "Slovensky" },
    { lang: "CZ", label: "Česky" },
    // { lang: "PL", label: "Polski" },
    // { lang: "EN", label: "English" },
    // { lang: "DE", label: "Deutsch" },
  ];

  return (
    <div className="py-12 flex flex-col gap-8">
      {/* Desktop view: Title without the back arrow */}
      <h1 className="lg:block hidden text-3xl font-bold uppercase">
        {t("Materiály")}
      </h1>

      {/* Mobile view: Title with the back arrow */}
      <div
        className="lg:hidden text-3xl font-bold uppercase flex items-center gap-2 cursor-pointer"
        onClick={onMenuToggle}
      >
        <IoIosArrowBack className="h-6 w-6 cursor-pointer text-gray-700" />
        {t("Materiály")}
      </div>

      {/* Career Plan Section */}
      <div className="flex flex-col gap-2">
        <div className="text-3xl font-semibold">{t("Kariérny plán")}</div>
        {careerPlanDocs.map((doc) => (
          <a
            key={doc.lang}
            target="_blank"
            rel="noopener noreferrer"
            href={`https://bbuy.fra1.cdn.digitaloceanspaces.com/materials/${doc.lang}_KARIE%CC%81RNY%20PLA%CC%81N%20BB.pdf`}
            className="flex flex-row gap-2 items-center"
          >
            {t("Stiahnuť")} ({doc.label}) <LuDownload />
          </a>
        ))}
      </div>

      {/* Presentation Section */}
      <div className="flex flex-col gap-2">
        <div className="text-3xl font-semibold">{t("Prezentácia")}</div>
        <div className="flex flex-col gap-2">
          <a
            href="https://www.canva.com/design/DAGU4T7AODg/aUNEpucSHEQ1wMMkfMbfng/edit"
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-row gap-2 items-center text-primary"
          >
            {t("Prezentácia")}
          </a>
          <button
            onClick={() => setShowInstructions(!showInstructions)}
            className={`flex flex-row gap-2 items-center w-fit px-3 py-1.5 rounded-md transition-colors ${
              showInstructions ? "bg-primary text-white" : "hover:bg-gray-100"
            }`}
          >
            {t("Návod")}
          </button>
          <AnimatePresence>
            {showInstructions && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="overflow-hidden"
              >
                <motion.div
                  initial={{ y: -20 }}
                  animate={{ y: 0 }}
                  exit={{ y: -20 }}
                  className="bg-gray-50 p-4 rounded-lg mt-2"
                >
                  <h3 className="font-bold mb-4">
                    {t("Návod, ako spustiť prezentáciu Bilionbuy")}
                  </h3>
                  <ol className="list-decimal pl-4 space-y-2">
                    <li>
                      {t("Kliknem na")}{" "}
                      <a
                        href="https://www.canva.com/design/DAGU4T7AODg/aUNEpucSHEQ1wMMkfMbfng/edit"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary hover:underline"
                      >
                        LINK
                      </a>{" "}
                      {t("prezentácie.")}
                    </li>
                    <li>{t("Zobrazí sa vám prezentácia.")}</li>
                    <li>
                      {t(
                        "Prezentáciu spustíte kliknutím na šípky 'Prezentovať na celej obrazovke', ktoré sú umiestnené na obrazovke vpravo dole nad dátumom a časom."
                      )}
                    </li>
                    <li>
                      {t(
                        "Prezentácia tak bude zobrazená na celej obrazovke a prezentujete ju vlastným tempom, posúvate sa na ďalší slide šípkou doprava → alebo kliknutím na 'Enter', na predchádzajúci slide šípkou doľava ←."
                      )}
                    </li>
                  </ol>
                  <div className="mt-4">
                    <img
                      src="https://bbuy.fra1.cdn.digitaloceanspaces.com/instructions/prez2.png"
                      alt="Instruction 1"
                      className="mb-4 max-w-full"
                    />
                    <p className="mb-4">
                      {t(
                        "Prezentáciu ukončíte tak, že sa presuniete na spodnú lištu obrazovky, kde sa zobrazí sivý panel a opäť kliknete na šípky 'Ukončiť zobrazenie na celú obrazovku' alebo kliknutím na tlačidlo klávesnice 'Esc'."
                      )}
                    </p>
                    <img
                      src="https://bbuy.fra1.cdn.digitaloceanspaces.com/instructions/prez1.png"
                      alt="Instruction 2"
                      className="max-w-full"
                    />
                  </div>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
          {/* <div className="text-gray-500">mp3 + text (čoskoro dostupné)</div> */}
        </div>
      </div>

      {/* Social Media Graphics Section */}
      <div className="flex flex-col gap-2">
        <div className="text-3xl font-semibold">
          {t("Grafiky na sociálne siete")}
        </div>
        <a
          href="https://drive.google.com/drive/folders/1NqUKMcxQvAyZVXMuD_o0fWg9-bRnxmWf"
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-row gap-2 items-center text-primary"
        >
          {t("Link na úložisko")}
        </a>
      </div>
    </div>
  );
};

export default Materials;

// https://bbuy.fra1.cdn.digitaloceanspaces.com/materials/SK_KARIE%CC%81RNY%20PLA%CC%81N%20BB.pdf

import React, { useState, useEffect } from "react";
import { Card, CardBody, Image, Button, Link } from "@nextui-org/react";
import { FaStar } from "react-icons/fa";
import { BiSolidHeartCircle } from "react-icons/bi";
import { withAdultContent } from "./hoc/withAdultContent";
import { useAdultContent } from "../providers/AdultContentProvider";
import { useTranslation } from "react-i18next";
import { useCart } from "../contexts/CartContext";
import { Tooltip } from "@nextui-org/react";
const CartIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-5 w-5 inline-block ml-2"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
    />
  </svg>
);

const cleanText = (htmlString) => {
  return htmlString?.replace(/<\/?[^>]+(>|$)/g, "");
};

const ProductItemCard = ({
  product,
  view,
  onVerified,
  bilionbuyShop,
  ...props
}) => {
  const { t } = useTranslation();
  const currency = localStorage.getItem("currency") || "EUR";
  const [isMobile, setIsMobile] = useState(false);
  const { requestAgeConfirmation } = useAdultContent();
  const [isVerified, setIsVerified] = useState(false);
  const { addToCart } = useCart();

  const handleProductClick = (e) => {
    if (product.adult_content) {
      e.preventDefault();
      requestAgeConfirmation(() => {
        window.location.href = `/view-product/${product.slug}`;
      });
    }
  };

  useEffect(() => {
    const verifyContent = async () => {
      if (product.adult_content) {
        if (!isVerified) {
          requestAgeConfirmation(() => {
            setIsVerified(true);
            onVerified?.();
          });
        }
      } else {
        setIsVerified(true);
        onVerified?.();
      }
    };

    verifyContent();
  }, [product.adult_content, requestAgeConfirmation]);

  const handleWishlistClick = (e) => {
    e.stopPropagation();
    console.log("Wishlist clicked for product: ", product.slug);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleAddToCart = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addToCart({
      id: null,
      product_id: product.id,
      name: product.product_name,
      price_vat: product.product_price_min,
      img_url: product.product_image_url,
      shop_name: "Bilionbuy",
      quantity: 1,
    });
  };

  if (!isVerified) {
    return null;
  }

  // Grid View Layout
  const renderGridView = () => (
    <div
      className="border-none w-full max-w-[380px] flex flex-col justify-between gap-4 h-full px-4"
      shadow="md"
      onClick={handleProductClick}
    >
      <div className="flex flex-col items-center gap-4 bg-white">
        <div className="shadow-xl rounded-xl relative w-full aspect-square flex justify-center items-center max-w-full overflow-hidden">
          <a href={`/view-product/${product.slug}`}>
            <Image
              alt={product.product_name}
              className="object-contain max-w-full max-h-full z-10"
              shadow="none"
              src={product.product_image_url}
            />
          </a>
          {product.top ? (
            <span className="absolute top-4 left-4 font-semibold text-white bg-pink px-4 py-1 rounded-3xl z-50">
              Top
            </span>
          ) : null}

          {/* <div className="absolute top-4 right-4 z-20">
            <BiSolidHeartCircle
              className="text-4xl hover:scale-110 hover:cursor-pointer"
              onClick={handleWishlistClick}
            />
          </div> */}
        </div>
        <div className="flex flex-col gap-4 w-full px-2">
          {/* <a href={`/view-product/${product.slug}`}>
            <div className="font-semibold hover:underline">
              {product.product_price_min === product.product_price_max
                ? `${product.product_price_min} ${
                    product.language === "sk" ? "€" : "CZK"
                  }`
                : `${product.product_price_min} - ${product.product_price_max} ${
                    product.language === "sk" ? "€" : "CZK"
                  }`}
            </div>
          </a> */}
          <div className="flex flex-row gap-2 items-center mt-2">
            <a href={`/view-product/${product.slug}`}>
              <div className="font-semibold hover:underline">
                {product.product_price_min === product.product_price_max
                  ? `${product.product_price_min} ${
                      product.language === "sk" ? "€" : "CZK"
                    }`
                  : `${product.product_price_min} - ${product.product_price_max} ${
                      product.language === "sk" ? "€" : "CZK"
                    }`}
              </div>
            </a>
            {product.direct_cashback ? (
              <Tooltip
                content={t("Cashback, ktorý získate pri nákupe tohto produktu")}
              >
                <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl text-xs">
                  {product.direct_cashback ? product.direct_cashback : null}
                </a>
              </Tooltip>
            ) : null}
            {product.points ? (
              <Tooltip
                content={t("Body, ktoré získate pri nákupe tohto produktu")}
              >
                <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl text-xs ">
                  {product.points ? product.points : null}
                </a>
              </Tooltip>
            ) : null}
          </div>
          <a href={`/view-product/${product.slug}`}>
            <h3 className="font-bold text-foreground/90 line-clamp-2">
              {product.product_name}
            </h3>
          </a>
          <h1 className="text-base text-lightgray line-clamp-4">
            {cleanText(product.product_description)}
          </h1>

          {product.review_score ? (
            <div className="flex flex-row gap-1 items-center">
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <div className="text-sm">
                {product.review_count
                  ? `${product.review_count} ${t("recenzií")}`
                  : t("Zatiaľ neboli pridané žiadne recenzie")}
              </div>
            </div>
          ) : (
            <div className="text-sm">
              {product.review_count
                ? `${product.review_count} ${t("recenzií")}`
                : t("Zatiaľ neboli pridané žiadne recenzie")}
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-start gap-2">
        <Button
          as={Link}
          className="bg-primary text-white px-8 rounded-3xl mb-8"
          href={`/view-product/${product.slug}`}
        >
          {t("Zobraziť produkt")} &rarr;
        </Button>
        {bilionbuyShop && (
          <Button
            className="bg-pink text-white rounded-3xl px-6"
            onClick={handleAddToCart}
          >
            {t("Do košíka")} <CartIcon />
          </Button>
        )}
      </div>
    </div>
  );

  // List View Layout
  const renderListView = () => (
    <div
      className="border-none w-full flex flex-row justify-between gap-4 h-full p-4 rounded-lg group"
      shadow="md"
      onClick={handleProductClick}
    >
      <div className="flex-shrink-0 w-[150px] h-[150px] border-1 p-4 rounded-lg shadow-lg relative overflow-hidden">
        <a href={`/view-product/${product.slug}`}>
          <Image
            alt={product.product_name}
            className="object-cover w-full h-full rounded-lg group-hover:scale-110 transition-transform"
            shadow="none"
            src={product.product_image_url}
          />
        </a>
        {product.top ? (
          <span className="absolute top-2 left-2 font-semibold text-white bg-pink px-2 py-1 rounded-3xl z-50">
            Top
          </span>
        ) : null}
        {/* <div className="absolute top-2 right-2 z-20">
          <BiSolidHeartCircle
            className="text-3xl hover:scale-110 hover:cursor-pointer"
            onClick={handleWishlistClick}
          />
        </div> */}
      </div>
      <div className="flex flex-col justify-between flex-grow w-full px-2">
        <div>
          <a href={`/view-product/${product.slug}`}>
            <h3 className="font-bold text-foreground/90 text-lg">
              {product.product_name}
            </h3>
          </a>
          <div className="flex flex-row gap-2 items-center mt-2">
            <a href={`/view-product/${product.slug}`}>
              <div className="font-semibold hover:underline">
                {product.product_price_min === product.product_price_max
                  ? `${product.product_price_min} ${
                      product.language === "sk" ? "€" : "CZK"
                    }`
                  : `${product.product_price_min} - ${product.product_price_max} ${
                      product.language === "sk" ? "€" : "CZK"
                    }`}
              </div>
            </a>
            {product.direct_cashback ? (
              <Tooltip
                content={t("Cashback, ktorý získate pri nákupe tohto produktu")}
              >
                <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl text-xs">
                  {product.direct_cashback ? product.direct_cashback : null}
                </a>
              </Tooltip>
            ) : null}
            {product.points ? (
              <Tooltip
                content={t("Body, ktoré získate pri nákupe tohto produktu")}
              >
                <a className="bg-pink text-white py-1 font-semibold px-2 rounded-3xl text-xs ">
                  {product.points ? product.points : null}
                </a>
              </Tooltip>
            ) : null}
          </div>
          <h1 className="text-sm text-lightgray mt-2 line-clamp-3 max-w-screen-2xl">
            {cleanText(product.product_description)}
          </h1>
        </div>
        <div className="flex items-center mt-4">
          {product.review_score ? (
            <div className="flex items-center gap-1">
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <FaStar className="text-primary" />
              <div className="text-sm">
                {product.review_count
                  ? `${product.review_count} ${t("recenzií")}`
                  : t("Zatiaľ neboli pridané žiadne recenzie")}
              </div>
            </div>
          ) : (
            <div className="text-sm">
              {product.review_count
                ? `${product.review_count} ${t("recenzií")}`
                : t("Zatiaľ neboli pridané žiadne recenzie")}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col justify-end">
        <Button
          as={Link}
          className="bg-primary text-white px-8 rounded-3xl mb-8"
          href={`/view-product/${product.slug}`}
        >
          {t("Zobraziť produkt")} &rarr;
        </Button>
      </div>
    </div>
  );

  // Render based on the screen size and the view prop
  return isMobile || view === "grid" ? renderGridView() : renderListView();
};

export default withAdultContent(ProductItemCard);

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  CircularProgress,
} from "@mui/material";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import api from "../../../services/api";

const DeliveryOptionsTable = () => {
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState("all");
  const [formData, setFormData] = useState({
    name: "",
    price: "",
    treshold: "",
    country: "",
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteOptionId, setDeleteOptionId] = useState(null);

  // Add country flag mapping
  const countryFlags = {
    SK: "🇸🇰",
    CZ: "🇨🇿",
    all: "🌍",
  };

  // Add this helper function after the countryFlags object
  const getCurrency = (countryCode) => {
    switch (countryCode) {
      case "CZ":
        return "CZK";
      case "SK":
      default:
        return "EUR";
    }
  };

  // Add a price formatting helper
  const formatPrice = (price, countryCode) => {
    if (!price) return "0 " + getCurrency(countryCode);
    return `${Number(price).toFixed(2)} ${getCurrency(countryCode)}`;
  };

  // Helper function to render country with flag
  const renderCountryWithFlag = (countryCode) => {
    const flag = countryFlags[countryCode] || countryFlags.all;
    const name =
      {
        SK: "Slovensko",
        CZ: "Česká republika",
        all: "Všetky",
      }[countryCode] || "Všetky";

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <span>{flag}</span>
        <span>{name}</span>
      </Box>
    );
  };

  const fetchDeliveryOptions = async (country = "all") => {
    try {
      setLoading(true);
      const url =
        country === "all"
          ? "eshop/delivery-options/"
          : `eshop/delivery-options/?country=${country}`;
      const response = await api.get(url);

      if (response.data && Array.isArray(response.data.results)) {
        setDeliveryOptions(response.data.results);
      } else if (Array.isArray(response.data)) {
        setDeliveryOptions(response.data);
      } else {
        setDeliveryOptions([]);
      }
    } catch (error) {
      console.error("Error fetching delivery options:", error);
      setDeliveryOptions([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveryOptions(selectedCountry);
  }, [selectedCountry]);

  const handleOpen = (option = null) => {
    if (option) {
      setFormData(option);
      setSelectedOption(option);
    } else {
      setFormData({ name: "", price: "", treshold: "", country: "" });
      setSelectedOption(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedOption(null);
  };

  const handleSubmit = async () => {
    try {
      if (selectedOption) {
        await api.put(`eshop/delivery-options/${selectedOption.id}/`, formData);
      } else {
        await api.post("eshop/delivery-options/", formData);
      }
      fetchDeliveryOptions(selectedCountry);
      handleClose();
    } catch (error) {
      console.error("Error saving delivery option:", error);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteOptionId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`eshop/delivery-options/${deleteOptionId}/`);
      fetchDeliveryOptions(selectedCountry);
    } catch (error) {
      console.error("Error deleting delivery option:", error);
    } finally {
      setDeleteDialogOpen(false);
      setDeleteOptionId(null);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Krajina</InputLabel>
          <Select
            value={selectedCountry}
            label="Krajina"
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <MenuItem value="all">{renderCountryWithFlag("all")}</MenuItem>
            <MenuItem value="SK">{renderCountryWithFlag("SK")}</MenuItem>
            <MenuItem value="CZ">{renderCountryWithFlag("CZ")}</MenuItem>
          </Select>
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpen()}
        >
          Pridať novú možnosť doručenia
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Názov</TableCell>
              <TableCell>Cena</TableCell>
              <TableCell>Hranica bezplatného doručenia</TableCell>
              <TableCell>Krajina</TableCell>
              <TableCell>Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : deliveryOptions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Neboli nájdené žiadne možnosti doručenia
                </TableCell>
              </TableRow>
            ) : (
              deliveryOptions.map((option) => (
                <TableRow key={option.id}>
                  <TableCell>{option.name}</TableCell>
                  <TableCell>
                    {formatPrice(option.price, option.country)}
                  </TableCell>
                  <TableCell>
                    {formatPrice(option.treshold, option.country)}
                  </TableCell>
                  <TableCell>
                    {renderCountryWithFlag(option.country || "all")}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(option)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(option.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedOption
            ? "Upraviť možnosť doručenia"
            : "Pridať možnosť doručenia"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Názov"
            fullWidth
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          />
          <TextField
            margin="dense"
            label={`Cena (${getCurrency(formData.country)})`}
            type="number"
            fullWidth
            value={formData.price}
            onChange={(e) =>
              setFormData({ ...formData, price: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label={`Hranica bezplatného doručenia (${getCurrency(formData.country)})`}
            type="number"
            fullWidth
            value={formData.treshold}
            onChange={(e) =>
              setFormData({ ...formData, treshold: e.target.value })
            }
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Krajina</InputLabel>
            <Select
              value={formData.country || ""}
              label="Krajina"
              onChange={(e) =>
                setFormData({ ...formData, country: e.target.value })
              }
            >
              <MenuItem value="">{renderCountryWithFlag("all")}</MenuItem>
              <MenuItem value="SK">{renderCountryWithFlag("SK")}</MenuItem>
              <MenuItem value="CZ">{renderCountryWithFlag("CZ")}</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zrušiť</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Uložiť
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Potvrdiť vymazanie</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 1 }}>
            Naozaj chcete vymazať túto možnosť doručenia?
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Zrušiť</Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
          >
            Vymazať
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeliveryOptionsTable;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import api from "../../services/api";
import BasicNavbar from "../BasicNavbar";
import Footer from "../Footer";

const logo_url =
  "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/bb-white.png";

const PaymentSuccess = () => {
  const [status, setStatus] = useState("loading");
  const [orderNumber, setOrderNumber] = useState(null);
  const location = useLocation();
  const sessionId = new URLSearchParams(location.search).get("session_id");

  useEffect(() => {
    let attempts = 0;
    const maxAttempts = 10;
    let checkInterval;

    const verifyPayment = async () => {
      if (!sessionId) {
        console.error("No session ID found in URL");
        setStatus("error");
        return true; // Stop checking
      }

      try {
        console.log("Verifying payment for session:", sessionId);
        const response = await api.get(
          `/eshop/payments/verify/?session_id=${sessionId}`
        );
        console.log("Verification response:", response.data);

        if (response.data.status === "error") {
          console.error("Payment verification failed:", response.data.message);
          setStatus("error");
          return true; // Stop checking
        }

        // Set order number as soon as we have it
        if (response.data.order_number) {
          setOrderNumber(response.data.order_number);
        }

        if (response.data.status === "pending") {
          attempts++;
          console.log(
            `Payment still pending. Attempt ${attempts} of ${maxAttempts}`
          );

          if (attempts >= maxAttempts) {
            console.error("Max verification attempts reached");
            setStatus("error");
            return true; // Stop checking
          }

          return false; // Continue checking
        }

        if (
          response.data.status === "completed" ||
          response.data.status === "success"
        ) {
          setStatus("success");
          return true; // Stop checking
        }

        // Unknown status
        console.error("Unknown payment status:", response.data.status);
        setStatus("error");
        return true; // Stop checking
      } catch (error) {
        console.error("Payment verification failed:", error);
        console.error("Error details:", error.response?.data);
        setStatus("error");
        return true; // Stop checking
      }
    };

    const startVerification = async () => {
      // First immediate check
      const shouldStop = await verifyPayment();

      if (!shouldStop) {
        // Start polling only if first check didn't resolve the status
        checkInterval = setInterval(async () => {
          const shouldStop = await verifyPayment();
          if (shouldStop) {
            clearInterval(checkInterval);
          }
        }, 2000); // Check every 2 seconds
      }
    };

    startVerification();

    // Cleanup
    return () => {
      if (checkInterval) {
        clearInterval(checkInterval);
      }
    };
  }, [sessionId]);

  if (status === "loading") {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
        gap={2}
      >
        <CircularProgress />
        <Typography>Overujeme stav platby...</Typography>
      </Box>
    );
  }

  return (
    <div className="max-w-screen-xl mx-auto px-4 py-12">
      <Box
        sx={{
          maxWidth: 600,
          mx: "auto",
          p: 4,
          textAlign: "center",
          bgcolor: "white",
          borderRadius: 2,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src={logo_url}
          alt="BilionBuy"
          style={{
            width: "200px",
            marginBottom: "2rem",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />

        {status === "success" ? (
          <>
            <Box
              sx={{
                width: 60,
                height: 60,
                borderRadius: "50%",
                bgcolor: "#0066FF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mx: "auto",
                mb: 3,
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="20 6 9 17 4 12" />
              </svg>
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                mb: 1,
                fontSize: "2rem",
              }}
            >
              Ďakujeme
            </Typography>
            <Typography
              variant="h5"
              sx={{
                mb: 3,
                fontSize: "1.5rem",
              }}
            >
              za váš nákup!
            </Typography>
            {orderNumber && (
              <Typography
                sx={{
                  color: "text.secondary",
                  mt: 2,
                }}
              >
                Číslo objednávky: #{orderNumber}
              </Typography>
            )}
          </>
        ) : (
          <>
            <Box
              sx={{
                width: 60,
                height: 60,
                borderRadius: "50%",
                bgcolor: "#FF3B30",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mx: "auto",
                mb: 3,
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                mb: 1,
                color: "#FF3B30",
                fontSize: "2rem",
              }}
            >
              Nastala chyba
            </Typography>
            <Typography>
              Platbu sa nepodarilo overiť.
              {orderNumber && (
                <>
                  <br />
                  Prosím, kontaktujte našu podporu s číslom objednávky #
                  {orderNumber}.
                </>
              )}
            </Typography>
          </>
        )}
      </Box>
    </div>
  );
};

export default PaymentSuccess;

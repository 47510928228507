import React from "react";
import { Box, Typography, Paper, Grid, Divider } from "@mui/material";

const OrderReview = ({
  cartTotals,
  deliveryDetails,
  selectedDelivery,
  selectedPayment,
  deliveryOptions,
  deliveryPrice,
  cart,
  calculateTotal,
}) => {
  if (!cartTotals || !deliveryDetails) {
    return null;
  }

  const total_vat = cartTotals?.total_vat ?? 0;
  const total_amount = cartTotals?.total_amount ?? 0;
  const subtotal = total_amount - total_vat;

  const getDeliveryPrice = () => {
    if (!selectedDelivery || !deliveryOptions.length) return 0;

    const selectedOption = deliveryOptions.find(
      (opt) => opt.name === selectedDelivery
    );
    if (selectedOption) {
      const cartTotal = cartTotals?.total_amount || 0;
      return cartTotal < selectedOption.threshold ? selectedOption.price : 0;
    }
    return 0;
  };

  return (
    <Box className="max-w-screen-xl mx-auto px-4">
      <Typography variant="h5" gutterBottom>
        Kontrola objednávky
      </Typography>

      {/* Products section */}
      <Typography variant="h6" gutterBottom>
        Objednávané produkty
      </Typography>
      <Paper sx={{ mb: 4 }}>
        {cart.map((item) => (
          <Box
            key={item.id}
            sx={{
              p: 2,
              borderBottom: "1px solid #eee",
              "&:last-child": { borderBottom: "none" },
            }}
          >
            <Box display="flex" gap={2}>
              <img
                src={item.image}
                alt={item.name}
                style={{ width: 80, height: 80, objectFit: "contain" }}
              />
              <Box flex={1}>
                <Typography variant="h6">{item.name}</Typography>
                <Box display="flex" gap={2} mt={1}>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Body
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="primary"
                    >
                      {(item.points * item.quantity).toFixed(3)}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption" color="text.secondary">
                      Cashback
                    </Typography>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="success.main"
                    >
                      {(item.cashback * item.quantity).toFixed(2)}€
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box textAlign="right">
                <Typography variant="body2" color="text.secondary">
                  {item.quantity} ks × {item.price.toFixed(2)}€
                </Typography>
                <Typography variant="h6" color="primary">
                  {(item.price * item.quantity).toFixed(2)}€
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Paper>

      {/* Delivery Details */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Dodacie údaje
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Meno a priezvisko
            </Typography>
            <Typography>
              {deliveryDetails.firstName} {deliveryDetails.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Kontakt
            </Typography>
            <Typography>{deliveryDetails.email}</Typography>
            <Typography>{deliveryDetails.phone}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              Adresa doručenia
            </Typography>
            <Typography>{deliveryDetails.street}</Typography>
            <Typography>
              {deliveryDetails.postalCode} {deliveryDetails.city}
            </Typography>
          </Grid>
          {deliveryDetails.isCompany && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="text.secondary">
                Fakturačné údaje
              </Typography>
              <Typography>{deliveryDetails.companyName}</Typography>
              <Typography>IČO: {deliveryDetails.ico}</Typography>
              <Typography>DIČ: {deliveryDetails.dic}</Typography>
              {deliveryDetails.ic_dph && (
                <Typography>IČ DPH: {deliveryDetails.ic_dph}</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Payment and Delivery Method */}
      <Paper sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Spôsob doručenia a platby
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Spôsob doručenia
            </Typography>
            <Typography>{selectedDelivery}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2" color="text.secondary">
              Spôsob platby
            </Typography>
            <Typography>
              {selectedPayment === "card"
                ? "Platba kartou"
                : selectedPayment === "transfer"
                  ? "Bankový prevod"
                  : selectedPayment}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Summary section */}
      <Paper sx={{ p: 3, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Súhrn objednávky
        </Typography>
        <Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>Suma bez DPH:</Typography>
            <Typography>
              {(cartTotals?.total_amount - cartTotals?.total_vat).toFixed(2)}€
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>DPH (20%):</Typography>
            <Typography>{cartTotals?.total_vat.toFixed(2)}€</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>Doprava:</Typography>
            <Typography>{getDeliveryPrice().toFixed(2)}€</Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Celkom na úhradu:</Typography>
            <Typography variant="h6" color="primary">
              {calculateTotal().toFixed(2)}€
            </Typography>
          </Box>
          <Box bgcolor="grey.100" p={2} borderRadius={1} mt={2}>
            <Typography variant="subtitle2" gutterBottom>
              Z tejto objednávky získate:
            </Typography>
            <Box display="flex" justifyContent="space-between">
              <Typography>Body:</Typography>
              <Typography color="primary" fontWeight="bold">
                {cart
                  .reduce((sum, item) => sum + item.points * item.quantity, 0)
                  .toFixed(3)}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography>Cashback:</Typography>
              <Typography color="success.main" fontWeight="bold">
                {cart
                  .reduce((sum, item) => sum + item.cashback * item.quantity, 0)
                  .toFixed(2)}
                €
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default OrderReview;

import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Modal,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Grid,
  CircularProgress,
  Pagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Visibility,
  VisibilityOff,
  Search as SearchIcon,
  Add as AddIcon,
  LocalOffer as LocalOfferIcon,
  ContentCopy,
} from "@mui/icons-material";
import api from "../../../services/api";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import RichTextEditor from "../../RichTextEditor";
import AutoselectFetchCreate from "../../AutoselectFetchCreate";
import BilionBuyProductEditModal from "./BilionBuyProductEditModal";
import ProductEditModal from "../ProductEditModal";
import BilionBuyOfferModal from "./BilionBuyOfferModal";
import ProductSelectorModal from "./ProductSelectorModal";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

export default function BilionBuyProductsTable() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isAddOfferModalOpen, setIsAddOfferModalOpen] = useState(false);
  const [newProduct, setNewProduct] = useState({
    product_name: "",
    original_name: "",
    product_description: "",
    language: "sk",
    product_manufacturer: "",
    product_assigned_category: "",
    product_image_url: "",
    product_ean: "",
    visibility: true,
    adult_content: false,
    shop_link_visibility: true,
    price_vat: "",
    price_novat: "",
    url: "",
    img_url: "",
    stock: "in_stock",
    stock_count: "",
    delivery_date: "",
    item_id: "",
    gift: "",
    extended_warranty: null,
    special_service: "",
    sales_voucher: null,
    cashback: "",
  });
  const [content, setContent] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryInputValue, setCategoryInputValue] = useState("");
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [isProductSelectorOpen, setIsProductSelectorOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [isEditOfferModalOpen, setIsEditOfferModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [totalItems, setTotalItems] = useState(0);

  const debouncedFetch = useCallback(
    debounce((searchValue) => {
      setPage(1);
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await api.get("/bilionbuy-products/", {
            params: {
              page,
              page_size: pageSize,
              ...(searchValue && { search: searchValue }),
            },
          });
          if (Array.isArray(response.data)) {
            setProducts(response.data);
            setTotalPages(Math.ceil(response.data.length / pageSize));
          } else if (response.data && response.data.results) {
            setProducts(response.data.results);
            setTotalPages(Math.ceil(response.data.count / pageSize));
            setTotalItems(response.data.count);
          } else {
            setProducts([]);
            setTotalPages(1);
          }
        } catch (error) {
          console.error("Error fetching products:", error);
          toast.error("Failed to fetch products");
          setProducts([]);
          setTotalPages(1);
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    }, 500),
    []
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedFetch(value);
  };

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get("/bilionbuy-products/", {
        params: {
          page,
          page_size: pageSize,
          ...(searchTerm && { search: searchTerm }),
        },
      });
      if (Array.isArray(response.data)) {
        setProducts(response.data);
        setTotalPages(Math.ceil(response.data.length / pageSize));
      } else if (response.data && response.data.results) {
        setProducts(response.data.results);
        setTotalPages(Math.ceil(response.data.count / pageSize));
        setTotalItems(response.data.count);
      } else {
        setProducts([]);
        setTotalPages(1);
      }
    } catch (error) {
      console.error("Error fetching products:", error);
      toast.error("Failed to fetch products");
      setProducts([]);
      setTotalPages(1);
    } finally {
      setIsLoading(false);
    }
  }, [page, pageSize, searchTerm]);

  useEffect(() => {
    if (!searchTerm) {
      fetchProducts();
    }
  }, [fetchProducts, page, pageSize]);

  useEffect(() => {
    if (selectedProduct) {
      setContent(selectedProduct.product_description || "");
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (isAddProductModalOpen) {
      setContent("");
    }
  }, [isAddProductModalOpen]);

  const fetchCategory = async (categoryId) => {
    try {
      const response = await api.get(`get-category/?id=${categoryId}`);
      const categoryData = Array.isArray(response.data)
        ? response.data[0]
        : response.data;
      setSelectedCategory(categoryData);
      setCategoryInputValue(categoryData.category_fullname || "");
    } catch (error) {
      console.error("Error fetching category:", error);
      toast.error("Failed to load product category");
    }
  };

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    setIsEditProductModalOpen(true);
  };

  const handleEditOffer = (product) => {
    setSelectedProduct(product);
    setIsEditOfferModalOpen(true);
  };

  const handleDeleteClick = (product) => {
    setProductToDelete(product);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`/bilionbuy-products/${productToDelete.id}/`);
      toast.success("Offer deleted successfully");
      setIsDeleteModalOpen(false);
      setProductToDelete(null);
      fetchProducts();
    } catch (error) {
      toast.error("Failed to delete offer");
      console.error("Error deleting offer:", error);
    }
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    try {
      await api.patch(`/bilionbuy-products/${selectedProduct.id}/`, {
        ...selectedProduct,
        product_description: content,
      });
      toast.success("Product updated successfully");
      setIsModalOpen(false);
      setContent("");
      fetchProducts();
    } catch (error) {
      toast.error("Failed to update product");
      console.error("Error updating product:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVisibilityToggle = async (id, currentVisibility) => {
    try {
      await api.patch(`/bilionbuy-products/${id}/`, {
        shop_link_visibility: !currentVisibility,
      });
      fetchProducts();
      toast.success("Shop link visibility updated successfully");
    } catch (error) {
      toast.error("Failed to update shop link visibility");
      console.error("Error updating shop link visibility:", error);
    }
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    try {
      await api.post("/bilionbuy-products/", newProduct);
      toast.success("Product added successfully");
      setIsAddProductModalOpen(false);
      setNewProduct({
        product_name: "",
        original_name: "",
        product_description: "",
        language: "sk",
        product_manufacturer: "",
        product_assigned_category: "",
        product_image_url: "",
        product_ean: "",
        visibility: true,
        adult_content: false,
        shop_link_visibility: true,
        price_vat: "",
        price_novat: "",
        url: "",
        img_url: "",
        stock: "in_stock",
        stock_count: "",
        delivery_date: "",
        item_id: "",
        gift: "",
        extended_warranty: null,
        special_service: "",
        sales_voucher: null,
        cashback: "",
      });
      fetchProducts();
    } catch (error) {
      toast.error("Failed to add product");
      console.error("Error adding product:", error);
    }
  };

  const handleCloseAddModal = () => {
    setIsAddProductModalOpen(false);
    setTimeout(() => {
      setNewProduct({
        product_name: "",
        original_name: "",
        product_description: "",
        language: "sk",
        product_manufacturer: "",
        product_assigned_category: "",
        product_image_url: "",
        product_ean: "",
        visibility: true,
        adult_content: false,
        shop_link_visibility: true,
        price_vat: "",
        price_novat: "",
        url: "",
        img_url: "",
        stock: "in_stock",
        stock_count: "",
        delivery_date: "",
        item_id: "",
        gift: "",
        extended_warranty: null,
        special_service: "",
        sales_voucher: null,
        cashback: "",
      });
      setContent("");
      setSelectedCategory(null);
      setCategoryInputValue("");
    }, 100);
  };

  const handleCloseEditModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
    setContent("");
    setSelectedCategory(null);
    setCategoryInputValue("");
  };

  const fetchCategories = useCallback(async () => {
    try {
      const response = await api.get("get-admin-categories/");
      setCategories(response.data?.results || []);
    } catch (error) {
      console.error("Error fetching categories:", error);
      toast.error("Failed to load categories");
      setCategories([]);
    }
  }, []);

  useEffect(() => {
    if (isModalOpen || isAddProductModalOpen) {
      fetchCategories();
    }
  }, [isModalOpen, isAddProductModalOpen, fetchCategories]);

  const handleAddOfferClick = () => {
    setIsProductSelectorOpen(true);
  };

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setIsAddOfferModalOpen(true);
  };

  const handleDuplicate = (product) => {
    console.log("Duplicating product:", product);

    const duplicateData = {
      product_name: product.product_name || "",
      original_name: product.original_name || "",
      product_description: product.product_description || "",
      language: product.language || "sk",
      product_manufacturer: product.product_manufacturer || "",
      product_assigned_category: product.product_assigned_category || "",
      product_image_url: product.product_image_url || "",
      product_ean: "",
      visibility: product.visibility ?? true,
      adult_content: product.adult_content ?? false,
      shop_link_visibility: product.shop_link_visibility ?? true,
      price_vat: product.price_vat || "",
      price_novat: product.price_novat || "",
      url: product.url || "",
      img_url: product.img_url || "",
      stock: product.stock || "in_stock",
      stock_count: product.stock_count || "",
      delivery_date: product.delivery_date || "",
      item_id: product.item_id || "",
      gift: product.gift || "",
      extended_warranty: product.extended_warranty || null,
      special_service: product.special_service || "",
      sales_voucher: product.sales_voucher || null,
      cashback: product.cashback || "",
    };

    console.log("Setting new product data:", duplicateData);

    // Set all the states at once
    setNewProduct(duplicateData);
    setContent(product.product_description || "");
    if (product.product_assigned_category) {
      fetchCategory(product.product_assigned_category);
    }
    setIsAddProductModalOpen(true);
  };

  useEffect(() => {
    console.log("newProduct state updated:", newProduct);
  }, [newProduct]);

  useEffect(() => {
    if (isAddProductModalOpen) {
      console.log("Modal opened with newProduct:", newProduct);
      console.log("Content:", content);
    }
  }, [isAddProductModalOpen, newProduct, content]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
        <TextField
          size="small"
          placeholder="Vyhľadať produkty..."
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: <SearchIcon />,
          }}
        />
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsAddProductModalOpen(true)}
        >
          Pridať produkt
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<LocalOfferIcon />}
          onClick={handleAddOfferClick}
        >
          Pridať ponuku
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Produkt</TableCell>
              <TableCell>Cena</TableCell>
              <TableCell>Skladom</TableCell>
              <TableCell>Viditeľnosť odkazu</TableCell>
              <TableCell>Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : products?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Nenašli sa žiadne produkty
                </TableCell>
              </TableRow>
            ) : (
              products.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <img
                        src={product.product_image_url}
                        alt={product.product_name}
                        style={{ width: 50, height: 50, objectFit: "cover" }}
                      />
                      <Box>
                        <Typography variant="body1">
                          {product.product_name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {product.product_manufacturer}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    {product.price_vat ? `${product.price_vat} €` : "-"}
                  </TableCell>
                  <TableCell>{product.stock_count || "-"}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() =>
                        handleVisibilityToggle(
                          product.id,
                          product.shop_link_visibility
                        )
                      }
                    >
                      {product.shop_link_visibility ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleDuplicate(product)}>
                      <ContentCopy />
                    </IconButton>
                    <IconButton onClick={() => handleEditProduct(product)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditOffer(product)}>
                      <LocalOfferIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(product)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          Zobrazené {(page - 1) * pageSize + 1} -{" "}
          {Math.min(page * pageSize, totalItems)} z {totalItems} položiek
        </Typography>

        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="5">5 na stránku</option>
            <option value="10">10 na stránku</option>
            <option value="50">50 na stránku</option>
            <option value="100">100 na stránku</option>
          </select>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
            color="primary"
          />
        </Box>
      </Box>

      <ProductEditModal
        isOpen={isEditProductModalOpen}
        onClose={() => {
          setIsEditProductModalOpen(false);
          setSelectedProduct(null);
        }}
        productId={selectedProduct?.id}
        onUpdate={fetchProducts}
      />

      <BilionBuyOfferModal
        isOpen={isEditOfferModalOpen}
        onClose={() => {
          setIsEditOfferModalOpen(false);
          setSelectedProduct(null);
        }}
        product={selectedProduct}
        onUpdate={fetchProducts}
      />

      <ProductSelectorModal
        isOpen={isProductSelectorOpen}
        onClose={() => setIsProductSelectorOpen(false)}
        onProductSelect={handleProductSelect}
      />

      <ProductEditModal
        isOpen={isAddProductModalOpen}
        onClose={handleCloseAddModal}
        onUpdate={fetchProducts}
        initialData={newProduct}
        content={content}
      />

      <BilionBuyOfferModal
        isOpen={isAddOfferModalOpen}
        onClose={() => {
          setIsAddOfferModalOpen(false);
          setSelectedProduct(null);
        }}
        product={selectedProduct}
        onUpdate={fetchProducts}
      />

      <Modal open={isModalOpen} onClose={handleCloseEditModal}>
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={2}>
            Upraviť produkt
          </Typography>
          <form onSubmit={handleUpdateProduct}>
            <Box sx={{ mb: 3 }}>
              <Typography variant="subtitle1" gutterBottom>
                Náhľad obrázka produktu
              </Typography>
              {selectedProduct?.product_image_url && (
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    border: "1px solid #ddd",
                    borderRadius: 1,
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <img
                    src={selectedProduct.product_image_url}
                    alt={selectedProduct.product_name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "contain",
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = "placeholder-image-url";
                    }}
                  />
                </Box>
              )}
            </Box>

            <Typography
              variant="h6"
              sx={{ mt: 2, mb: 1, color: "primary.main" }}
            >
              Detaily produktu
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Kategória
                </Typography>
                <AutoselectFetchCreate
                  fetchCall="search-categories"
                  createCall="categories"
                  value={selectedCategory}
                  setValue={(newValue) => {
                    setSelectedCategory(newValue);
                    setNewProduct((prev) => ({
                      ...prev,
                      product_assigned_category: newValue?.id || null,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Názov produktu"
                  name="product_name"
                  value={newProduct.product_name}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_name: e.target.value,
                      original_name: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Výrobca"
                  name="product_manufacturer"
                  value={newProduct.product_manufacturer}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_manufacturer: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="EAN"
                  name="product_ean"
                  value={newProduct.product_ean}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_ean: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Product Image URL"
                  name="product_image_url"
                  value={newProduct.product_image_url}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      product_image_url: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Description
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <RichTextEditor
                    content={newProduct.product_description || ""}
                    setContent={(newContent) =>
                      setNewProduct((prev) => ({
                        ...prev,
                        product_description: newContent,
                      }))
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newProduct.visibility}
                      onChange={(e) =>
                        setNewProduct((prev) => ({
                          ...prev,
                          visibility: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Produkt viditeľný"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newProduct.adult_content}
                      onChange={(e) =>
                        setNewProduct((prev) => ({
                          ...prev,
                          adult_content: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Obsah pre dospelých"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{ mt: 2, mb: 1, color: "primary.main" }}
                >
                  Detaily obchodnej ponuky
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  required
                  label="Price (VAT)"
                  name="price_vat"
                  type="number"
                  value={newProduct.price_vat}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      price_vat: e.target.value,
                      price_novat: (parseFloat(e.target.value) / 1.2).toFixed(
                        2
                      ),
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Stock Count"
                  name="stock_count"
                  type="number"
                  value={newProduct.stock_count}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      stock_count: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Delivery Date"
                  name="delivery_date"
                  value={newProduct.delivery_date}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      delivery_date: e.target.value,
                    }))
                  }
                  margin="normal"
                  placeholder="e.g., 2-3 business days"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Item ID"
                  name="item_id"
                  value={newProduct.item_id}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      item_id: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Cashback"
                  name="cashback"
                  type="number"
                  value={newProduct.cashback}
                  onChange={(e) =>
                    setNewProduct((prev) => ({
                      ...prev,
                      cashback: e.target.value,
                    }))
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={newProduct.shop_link_visibility}
                      onChange={(e) =>
                        setNewProduct((prev) => ({
                          ...prev,
                          shop_link_visibility: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Shop Link Visible"
                />
              </Grid>
            </Grid>

            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button onClick={handleCloseAddModal} sx={{ mr: 1 }}>
                Zrušiť
              </Button>
              <Button type="submit" variant="contained">
                Aktualizovať produkt
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal
        open={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setProductToDelete(null);
        }}
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={2}>
            Potvrdenie vymazania
          </Typography>
          <Typography variant="body1" mb={3}>
            Naozaj chcete vymazať ponuku produktu "
            {productToDelete?.product_name}"? Túto akciu nie je možné vrátiť
            späť.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button
              onClick={() => {
                setIsDeleteModalOpen(false);
                setProductToDelete(null);
              }}
            >
              Zrušiť
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteConfirm}
            >
              Vymazať
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

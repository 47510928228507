import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import ResetPasswordForm from "../components/auth/ResetPasswordForm";
import { useTranslation } from "react-i18next";

export default function ResetPasswordPage() {
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useTranslation();

  const handleResetSuccess = () => {
    alert(t("Heslo bolo úspešne obnovené"));
    navigate("/login");
  };

  return (
    <>
      <BasicNavbar />
      <ResetPasswordForm token={token} onSuccess={handleResetSuccess} />
      <Footer />
    </>
  );
}

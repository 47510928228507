import api from "./api";

export const cartService = {
  getCart: async () => {
    if (localStorage.getItem("jwt")) {
      const response = await api.get("/eshop/cart/");
      return response.data;
    }
    return [];
  },

  updateQuantity: async (itemId, quantity) => {
    const response = await api.post("/eshop/cart/update_quantity/", {
      item_id: itemId,
      quantity,
    });
    return response.data;
  },

  removeItem: async (itemId) => {
    const response = await api.post("/eshop/cart/remove_item/", {
      item_id: itemId,
    });
    return response.data;
  },

  getCartSummary: async () => {
    const response = await api.get("/eshop/cart/summary/");
    return response.data;
  },

  createOrder: async (orderData) => {
    const response = await api.post("/eshop/orders/", orderData);
    return response.data;
  },

  createPaymentIntent: async (orderId) => {
    const response = await api.post(
      `/eshop/orders/${orderId}/create_payment_intent/`
    );
    return response.data;
  },

  clearCart: async () => {
    const response = await api.post("/eshop/cart/clear/");
    return response.data;
  },

  cancelOrder: async (orderId) => {
    const response = await api.post(`/eshop/orders/${orderId}/cancel/`);
    return response.data;
  },

  addToCart: async (item) => {
    try {
      const response = await api.post("/eshop/cart/add_item/", item);
      return response.data;
    } catch (error) {
      console.error("Cart service error:", error);
      throw error; // Re-throw to be handled by the context
    }
  },
};

import React, { useState, useEffect } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  Collapse,
  Link,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Skeleton,
  Modal,
  Typography,
  Grid,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  Add as AddIcon,
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import api from "../../services/api";
import ProductEditModal from "./ProductEditModal";
import { toast } from "react-toastify";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const TableRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <Skeleton variant="circular" width={32} height={32} />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" width="80%" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" width="60%" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" width="70%" />
    </TableCell>
    <TableCell>
      <Skeleton variant="text" width="50%" />
    </TableCell>
    <TableCell>
      <Skeleton variant="rectangular" width={34} height={20} />
    </TableCell>
    <TableCell>
      <Skeleton variant="rectangular" width={34} height={20} />
    </TableCell>
    <TableCell>
      <Skeleton variant="circular" width={32} height={32} />
    </TableCell>
  </TableRow>
);

const OffersTableSkeleton = () => (
  <Box sx={{ margin: 1 }}>
    <Skeleton variant="rectangular" width={140} height={36} sx={{ mb: 2 }} />
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Obchod</TableCell>
          <TableCell>Cena s DPH</TableCell>
          <TableCell>URL</TableCell>
          <TableCell>Cashback</TableCell>
          <TableCell>Viditeľnosť</TableCell>
          <TableCell>Akcie</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {[1, 2].map((i) => (
          <TableRow key={i}>
            <TableCell>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Skeleton variant="circular" width={16} height={16} />
                <Skeleton variant="text" width="70%" />
              </Box>
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width="50%" />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width={40} />
            </TableCell>
            <TableCell>
              <Skeleton variant="text" width="40%" />
            </TableCell>
            <TableCell>
              <Skeleton variant="rectangular" width={34} height={20} />
            </TableCell>
            <TableCell>
              <Box sx={{ display: "flex", gap: 1 }}>
                <Skeleton variant="circular" width={32} height={32} />
                <Skeleton variant="circular" width={32} height={32} />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </Box>
);

const ProductRow = ({
  product,
  handleEditProduct,
  handleEditOffer,
  handleDeleteOffer,
  handleCreateOffer,
  handleDeleteDialogOpen,
}) => {
  const [open, setOpen] = useState(false);
  const [loadingOffers, setLoadingOffers] = useState(false);

  const handleToggle = () => {
    if (!open) {
      setLoadingOffers(true);
      setTimeout(() => {
        setLoadingOffers(false);
      }, 500);
    }
    setOpen(!open);
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton size="small" onClick={handleToggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <a
              href={"view-product/" + product.slug}
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row gap-1"
            >
              {product.product_image_url && (
                <img
                  src={product.product_image_url}
                  alt=""
                  style={{ width: 16, height: 16, objectFit: "cover" }}
                />
              )}
              {product.product_name}
            </a>
          </Box>
        </TableCell>
        <TableCell>{product.product_manufacturer}</TableCell>
        <TableCell>{product.product_assigned_category?.name}</TableCell>
        <TableCell>{product.product_ean}</TableCell>
        <TableCell>
          <Switch
            checked={product.visibility}
            onChange={() =>
              handleEditProduct(product.id, { visibility: !product.visibility })
            }
          />
        </TableCell>
        <TableCell>
          <Switch
            checked={product.adult_content}
            onChange={() =>
              handleEditProduct(product.id, {
                adult_content: !product.adult_content,
              })
            }
          />
        </TableCell>
        <TableCell>
          <IconButton onClick={() => handleEditProduct(product.id)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => handleDeleteDialogOpen("product", product)}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {loadingOffers ? (
              <OffersTableSkeleton />
            ) : (
              <Box sx={{ margin: 1 }}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => handleCreateOffer(product.id)}
                  sx={{ mb: 2 }}
                >
                  Pridať ponuku (PRIRADIŤ OBCHOD K MASKE PRODUKTU)
                </Button>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Obchod</TableCell>
                      <TableCell>Cena s DPH</TableCell>
                      <TableCell>URL</TableCell>
                      <TableCell>Upravený cashback</TableCell>
                      <TableCell>Viditeľnosť</TableCell>
                      <TableCell>Akcie</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {product.offers?.map((offer) => (
                      <TableRow key={offer.id}>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            {offer.img_url && (
                              <img
                                src={offer.img_url}
                                alt=""
                                style={{
                                  width: 16,
                                  height: 16,
                                  objectFit: "cover",
                                }}
                              />
                            )}
                            {offer.shop_name}
                          </Box>
                        </TableCell>
                        <TableCell>{offer.price_vat}</TableCell>
                        <TableCell>
                          <Link
                            href={offer.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {offer.url}
                          </Link>
                        </TableCell>
                        <TableCell>{offer.cashback}</TableCell>
                        <TableCell>
                          <Switch
                            checked={offer.visibility}
                            onChange={() =>
                              handleEditOffer(offer.id, {
                                visibility: !offer.visibility,
                              })
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleEditOffer(offer.id)}>
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              handleDeleteDialogOpen("offer", offer)
                            }
                            color="error"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TablePaginationActions = ({ onPageChange, page, count, rowsPerPage }) => {
  const [pageInput, setPageInput] = useState(page + 1);
  const maxPage = Math.ceil(count / rowsPerPage);

  const handlePageInputChange = (e) => {
    const value = e.target.value;
    setPageInput(value);
  };

  const handlePageInputKeyPress = (e) => {
    if (e.key === "Enter") {
      const newPage = Math.min(Math.max(1, parseInt(pageInput) || 1), maxPage);
      setPageInput(newPage);
      onPageChange(null, newPage - 1);
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <IconButton
        onClick={(e) => onPageChange(e, 0)}
        disabled={page === 0}
        aria-label="Prvá stránka"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, page - 1)}
        disabled={page === 0}
        aria-label="Predchádzajúca stránka"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <TextField
        size="small"
        type="number"
        value={pageInput}
        onChange={handlePageInputChange}
        onKeyPress={handlePageInputKeyPress}
        inputProps={{
          min: 1,
          max: maxPage,
          style: { width: "50px", textAlign: "center" },
        }}
      />
      <Typography variant="body2">z {maxPage}</Typography>
      <IconButton
        onClick={(e) => onPageChange(e, page + 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Ďalšia stránka"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={(e) => onPageChange(e, Math.ceil(count / rowsPerPage) - 1)}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Posledná stránka"
      >
        <LastPageIcon />
      </IconButton>
    </Box>
  );
};

const ProductsLinkedShopsTable = ({ shopFilter }) => {
  const [products, setProducts] = useState({
    results: [],
    total_items: 0,
    current_page: 1,
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editDialog, setEditDialog] = useState({
    open: false,
    type: null,
    data: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [country, setCountry] = useState("sk");
  const [loading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [shopOptions, setShopOptions] = useState([]);
  const [loadingShops, setLoadingShops] = useState(false);
  const [shopSearchTerm, setShopSearchTerm] = useState("");
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    type: null, // 'product' or 'offer'
    data: null,
  });

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      if (searchQuery) {
        params.append("name", searchQuery);
      }
      if (country) {
        params.append("country", country);
      }
      if (shopFilter) {
        params.append("shop", shopFilter);
      }
      params.append("page", page + 1);
      params.append("page_size", rowsPerPage);

      const queryString = params.toString();
      const url = `/products-with-offers/${queryString ? `?${queryString}` : ""}`;

      const response = await api.get(url);
      setProducts({
        results: response.data.results || [],
        total_items: response.data.total_items || 0,
        current_page: response.data.current_page || 1,
      });
    } catch (error) {
      console.error("Error fetching products:", error);
      setProducts({ results: [], total_items: 0, current_page: 1 });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      fetchProducts();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchQuery, page, rowsPerPage, country]);

  const handleEditProduct = async (productId, directUpdate = null) => {
    if (directUpdate) {
      try {
        await api.put(`/products/${productId}/update/`, directUpdate);
        fetchProducts();
      } catch (error) {
        console.error("Error updating product:", error);
      }
    } else {
      setSelectedProductId(productId);
      setEditModalOpen(true);
    }
  };

  const handleEditOffer = async (offerId, directUpdate = null) => {
    if (directUpdate) {
      try {
        await api.put(`/offers/${offerId}/update/`, directUpdate);
        fetchProducts();
      } catch (error) {
        console.error("Error updating offer:", error);
      }
    } else {
      const offer = products.results
        .flatMap((p) => p.offers)
        .find((o) => o.id === offerId);
      setEditDialog({ open: true, type: "offer", data: offer });
      if (offer.shop) {
        fetchShopById(offer.shop);
      }
    }
  };

  const handleDeleteOffer = async (offerId) => {
    try {
      await api.delete(`/offers/${offerId}/delete`);
      fetchProducts();
      handleDeleteDialogClose();
      toast.success("Ponuka bola úspešne vymazaná");
    } catch (error) {
      console.error("Error deleting offer:", error);
      toast.error("Chyba pri mazaní ponuky");
    }
  };

  const handleCreateOffer = (productId) => {
    setEditDialog({
      open: true,
      type: "newOffer",
      data: {
        product_id: productId,
        shop: null,
        shop_name: "",
        price_vat: "",
        url: "",
        img_url: "",
        cashback: "",
        visibility: true,
      },
    });
  };

  const handleDialogClose = () => {
    setEditDialog({ open: false, type: null, data: null });
  };

  const handleDialogSave = async () => {
    try {
      const { type, data } = editDialog;
      if (type === "product") {
        await api.put(`/products/${data.id}/update/`, data);
      } else if (type === "offer") {
        await api.put(`/offers/${data.id}/update/`, data);
      } else if (type === "newOffer") {
        await api.post(`/products/${data.product_id}/offers/create/`, data);
      }
      handleDialogClose();
      fetchProducts();
    } catch (error) {
      console.error("Error saving:", error);
    }
  };

  const handleCreateProduct = () => {
    setSelectedProductId(null);
    setEditModalOpen(true);
  };

  const handleModalClose = () => {
    setEditModalOpen(false);
    setSelectedProductId(null);
    fetchProducts(); // Refresh the list after modal closes
  };

  const fetchShopById = async (shopId) => {
    try {
      setLoadingShops(true);
      const response = await api.get(`get-shop/${shopId}/`);
      setShopOptions([response.data]);
    } catch (error) {
      console.error("Error fetching shop:", error);
      toast.error("Error fetching shop");
    } finally {
      setLoadingShops(false);
    }
  };

  useEffect(() => {
    const fetchShops = async () => {
      if (shopSearchTerm) {
        try {
          setLoadingShops(true);
          const response = await api.get("get-shops-names/", {
            params: { search: shopSearchTerm },
          });
          setShopOptions(response.data);
        } catch (error) {
          console.error("Error fetching shops:", error);
          toast.error("Error fetching shops");
        } finally {
          setLoadingShops(false);
        }
      } else {
        setShopOptions([]);
      }
    };

    const debounceTimer = setTimeout(() => {
      fetchShops();
    }, 300);

    return () => clearTimeout(debounceTimer);
  }, [shopSearchTerm]);

  const handleDeleteProduct = async (productId) => {
    try {
      await api.delete(`/products/${productId}/delete`);
      fetchProducts();
      handleDeleteDialogClose();
      toast.success("Produkt bol úspešne vymazaný");
    } catch (error) {
      console.error("Error deleting product:", error);
      toast.error("Chyba pri mazaní produktu");
    }
  };

  const handleDeleteDialogOpen = (type, data) => {
    setDeleteDialog({
      open: true,
      type,
      data,
    });
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialog({
      open: false,
      type: null,
      data: null,
    });
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleCreateProduct}
        >
          PRIDAŤ PRODUKT (MASKU PRODUKTU)
        </Button>
      </Box>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <TextField
              fullWidth
              label="Vyhľadať podľa názvu produktu, EAN alebo URL"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setPage(0);
              }}
            />
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="country-select-label">Krajina</InputLabel>
              <Select
                labelId="country-select-label"
                value={country}
                label="Krajina"
                onChange={(e) => {
                  setCountry(e.target.value);
                  setPage(0);
                }}
              >
                <MenuItem value="sk">Slovensko</MenuItem>
                <MenuItem value="cz">Česko</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Názov</TableCell>
                <TableCell>Výrobca</TableCell>
                <TableCell>Priradená kategória</TableCell>
                <TableCell>EAN</TableCell>
                <TableCell>Viditeľnosť</TableCell>
                <TableCell>Obsah 18+</TableCell>
                <TableCell>Akcie</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading
                ? [...Array(5)].map((_, index) => (
                    <TableRowSkeleton key={index} />
                  ))
                : products.results.map((product) => (
                    <ProductRow
                      key={product.id}
                      product={product}
                      handleEditProduct={handleEditProduct}
                      handleEditOffer={handleEditOffer}
                      handleDeleteOffer={handleDeleteOffer}
                      handleCreateOffer={handleCreateOffer}
                      handleDeleteDialogOpen={handleDeleteDialogOpen}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={products.total_items}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          labelRowsPerPage="Riadkov na stránku:"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}–${to} z ${count !== -1 ? count : `viac ako ${to}`}`
          }
          getItemAriaLabel={(type) => {
            if (type === "first") return "Prvá stránka";
            if (type === "last") return "Posledná stránka";
            if (type === "next") return "Ďalšia stránka";
            if (type === "previous") return "Predchádzajúca stránka";
          }}
          showFirstButton
          showLastButton
          ActionsComponent={TablePaginationActions}
        />
      </Paper>

      <ProductEditModal
        isOpen={editModalOpen}
        onClose={handleModalClose}
        productId={selectedProductId}
        onUpdate={fetchProducts}
      />

      <Modal
        open={
          editDialog.open &&
          (editDialog.type === "offer" || editDialog.type === "newOffer")
        }
        onClose={handleDialogClose}
        aria-labelledby="offer-edit-modal"
      >
        <Box sx={modalStyle}>
          <Typography variant="h6" component="h2" mb={2}>
            {editDialog.type === "offer" ? "Upraviť ponuku" : "Vytvoriť ponuku"}
          </Typography>

          <Grid container spacing={3}>
            {/* Main fields - Left column */}
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Základné údaje
              </Typography>
              <Autocomplete
                options={shopOptions}
                getOptionLabel={(option) => option.name}
                value={
                  shopOptions.find(
                    (shop) => shop.id === editDialog.data?.shop
                  ) || null
                }
                onChange={(event, newValue) => {
                  setEditDialog({
                    ...editDialog,
                    data: {
                      ...editDialog.data,
                      shop: newValue?.id || null,
                      shop_name: newValue?.name || "",
                    },
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  setShopSearchTerm(newInputValue);
                }}
                loading={loadingShops}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    margin="normal"
                    label="Obchod"
                    helperText="Vyberte obchod, z ktorého pochádza ponuka"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingShops ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Cena (s DPH)"
                helperText="Aktuálna cena produktu vrátane DPH"
                value={editDialog.data?.price_vat || ""}
                onChange={(e) =>
                  setEditDialog({
                    ...editDialog,
                    data: { ...editDialog.data, price_vat: e.target.value },
                  })
                }
              />
              <TextField
                fullWidth
                margin="normal"
                label="URL"
                helperText="Priamy odkaz na produkt v e-shope"
                value={editDialog.data?.url || ""}
                onChange={(e) =>
                  setEditDialog({
                    ...editDialog,
                    data: { ...editDialog.data, url: e.target.value },
                  })
                }
              />
              <TextField
                fullWidth
                margin="normal"
                label="URL obrázka"
                helperText="Odkaz na obrázok produktu z e-shopu"
                value={editDialog.data?.img_url || ""}
                onChange={(e) =>
                  setEditDialog({
                    ...editDialog,
                    data: { ...editDialog.data, img_url: e.target.value },
                  })
                }
              />
              <TextField
                fullWidth
                margin="normal"
                label="Cashback"
                type="number"
                helperText="Výška cashbacku v percentách (napr. 2.5) - vypĺňať iba ak je cashback pre daný produkt iný než nastavené cashbacky v obchode"
                value={editDialog.data?.cashback || ""}
                onChange={(e) =>
                  setEditDialog({
                    ...editDialog,
                    data: { ...editDialog.data, cashback: e.target.value },
                  })
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={editDialog.data?.visibility || false}
                    onChange={(e) =>
                      setEditDialog({
                        ...editDialog,
                        data: {
                          ...editDialog.data,
                          visibility: e.target.checked,
                        },
                      })
                    }
                  />
                }
                label="Viditeľnosť"
              />
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{ ml: 1 }}
              >
                Určuje, či bude ponuka viditeľná pre používateľov
              </Typography>
            </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button onClick={handleDialogClose} sx={{ mr: 1 }}>
              Zrušiť
            </Button>
            <Button onClick={handleDialogSave} variant="contained">
              Uložiť
            </Button>
          </Box>
        </Box>
      </Modal>

      <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteDialogClose}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          {deleteDialog.type === "product"
            ? "Vymazať produkt"
            : "Vymazať ponuku"}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {deleteDialog.type === "product"
              ? "Naozaj chcete vymazať tento produkt? Táto akcia sa nedá vrátiť späť."
              : "Naozaj chcete vymazať túto ponuku? Táto akcia sa nedá vrátiť späť."}
          </Typography>
          {deleteDialog.data && (
            <Box sx={{ mt: 2 }}>
              {deleteDialog.type === "product" ? (
                <>
                  <Typography>
                    <strong>Názov:</strong> {deleteDialog.data.product_name}
                  </Typography>
                  <Typography>
                    <strong>Výrobca:</strong>{" "}
                    {deleteDialog.data.product_manufacturer}
                  </Typography>
                  <Typography>
                    <strong>EAN:</strong> {deleteDialog.data.product_ean}
                  </Typography>
                </>
              ) : (
                <>
                  <Typography>
                    <strong>Obchod:</strong> {deleteDialog.data.shop_name}
                  </Typography>
                  <Typography>
                    <strong>Cena:</strong> {deleteDialog.data.price_vat}
                  </Typography>
                  <Typography>
                    <strong>URL:</strong> {deleteDialog.data.url}
                  </Typography>
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose}>Zrušiť</Button>
          <Button
            onClick={() =>
              deleteDialog.type === "product"
                ? handleDeleteProduct(deleteDialog.data.id)
                : handleDeleteOffer(deleteDialog.data.id)
            }
            color="error"
            variant="contained"
          >
            Vymazať
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ProductsLinkedShopsTable;

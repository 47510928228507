import React, { useState, useCallback } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  CircularProgress,
  InputAdornment,
  Grid,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { debounce } from "lodash";
import api from "../../../services/api";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
};

const ProductSelectorModal = ({ isOpen, onClose, onProductSelect }) => {
  const [nameFilter, setNameFilter] = useState("");
  const [urlFilter, setUrlFilter] = useState("");
  const [eanFilter, setEanFilter] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const fetchProducts = async (page = 1) => {
    setLoading(true);
    try {
      const response = await api.get("get-admin-products/", {
        params: {
          filter_name: nameFilter,
          filter_url: urlFilter,
          filter_ean: eanFilter,
          page,
          page_size: pageSize,
        },
      });

      setProducts(response.data.results || []);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetch = useCallback(
    debounce((page) => {
      fetchProducts(page);
    }, 300),
    [nameFilter, urlFilter, eanFilter, pageSize]
  );

  const handleSearch = () => {
    setCurrentPage(1);
    debouncedFetch(1);
  };

  const handleProductClick = (product) => {
    onProductSelect(product);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2" mb={2}>
          Select Product for Offer
        </Typography>

        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Name"
              value={nameFilter}
              onChange={(e) => setNameFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="URL"
              value={urlFilter}
              onChange={(e) => setUrlFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              label="EAN"
              value={eanFilter}
              onChange={(e) => setEanFilter(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Button
              variant="contained"
              onClick={handleSearch}
              disabled={loading}
              sx={{ height: "100%" }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {loading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {products.map((product) => (
              <ListItem
                key={product.id}
                button
                onClick={() => handleProductClick(product)}
                sx={{
                  border: "1px solid #eee",
                  borderRadius: 1,
                  mb: 1,
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    src={product.product_image_url}
                    alt={product.product_name}
                    variant="square"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={product.product_name}
                  secondary={
                    <>
                      {product.product_manufacturer}
                      {product.product_ean && ` • EAN: ${product.product_ean}`}
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        )}

        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProductSelectorModal;
